import axios from '../axios';

const BASE_URL = '/v2/staff/sms';

export default {
  getUsage: params =>
    axios.get(BASE_URL, {
      params,
    }),

  get: (id, filters) => {
    const typeConverter = type => (type === 'ALL' ? ['SMS', 'LMS'] : [type]); // ['SMS', 'LMS'] | ["SMS"] | ["LMS"]
    const params = {
      sms_type: typeConverter(filters?.sms_type),
      is_sent: filters?.is_sent,
      start_date: filters?.start_date,
      end_date: filters?.end_date,
      title: filters?.title,
      page: filters?.page,
      limit: filters?.limit ?? 10,
    };

    return axios.get(`${BASE_URL}/${id}`, { params });
  },

  create: studio_message => {
    return axios.post(`${BASE_URL}`, studio_message);
  },

  createStaffSms: studio_message => {
    return axios.post(`${BASE_URL}/send-app-download-link`, studio_message);
  },

  update: (id, studio_message) => {
    return axios.patch(`${BASE_URL}/${id}`, studio_message);
  },

  cancel: id => axios.delete(`${BASE_URL}/${id}`), // 예약문자 취소
  delete: id => axios.delete(`${BASE_URL}/${id}/delete`), // 취소된 예약문자 삭제

  // 자동 알림 발송 내역 (푸시 제외)
  automessage: {
    getAll: params => axios.get(`/v2/staff/message`, { params: { ...params, type: params.type ?? ['SMS', 'LMS'] } }),
    getOne: (id, params) => axios.get(`/v2/staff/message/${id}`, { params: { ...params, type: params.type ?? ['SMS', 'LMS'] } }),
  },
};
