<template>
  <el-card class="staff-card" shadow="hover" :body-style="card_body_style" :style="{ borderTop: `2px solid ${staff_color}` }">
    <div class="staff-card__contents" @click="handleClickCard">
      <div class="staff-card__contents__tags">
        <span v-if="staff.roles.length">
          {{ staff.roles[0].display_name }}
        </span>
        <span v-else class="staff-card__contents__tags__no-role">
          역할지정 필요
        </span>
        <span v-if="isMe" class="staff-card__contents__tags__me">
          Me
        </span>
      </div>
      <img
        class="staff-card__contents__image"
        :src="$utils.getImageUrl(staff.avatars[0], '90x90')"
        :alt="`${staff.profile.name} 프로필 사진`"
      />
      <p class="staff-card__contents__name">
        {{ staff.profile.name }}
      </p>
      <p class="staff-card__contents__mobile">
        {{ staff_mobile | mobile }}
      </p>
      <p class="staff-card__contents__applink" v-if="isStaffAppLinked">앱 연결: 연결</p>
      <div class="staff-card__contents__appdownload">
        <button v-if="isStaffAppDownloadLinked" @click.stop="showMessageDialog = true" :disabled="!canSendAnyMessage">
          <p>앱 다운로드 링크</p>
        </button>
      </div>

      <div class="staff-card__contents__account">
        <button v-if="isMe" @click.stop="handleAccountAction">
          비밀번호 재설정
        </button>
      </div>
    </div>

    <div class="staff-card__buttons">
      <button v-if="canDeleteStaff && !isMe" class="staff-card__buttons__delete" @click="deleteStaff(staff)">
        삭제
      </button>
      <button v-if="canUpdateStaff" class="staff-card__buttons__edit" @click="$router.push(`/staffs/edit?id=${staff.id}`)">
        수정
      </button>
    </div>
    <el-dialog class="message-modal" :visible.sync="showMessageDialog">
      <CustomTabs
        class="message-modal__tabs"
        :active_tab="messageTypeTab"
        :tabs="[{ value: 'sms', label: 'SMS 보내기' }]"
        :handleChangeTab="handleMessageTab"
      />
      <MessageForm
        v-if="showMessageDialog"
        hide-member-list-button
        is-on-modal
        is-staff
        :staff="staff"
        :messageType="messageTypeTab"
        :defaultMessageTitle="'관리자앱 다운로드 링크입니다.'"
        :defaultMessage="defaultAppDownloadMessage"
        @messageSent="showMessageDialog = false"
      />
    </el-dialog>
  </el-card>
</template>

<script>
import MessageForm from '@/components/Messages/Form';

export default {
  props: {
    staff: Object,
    deleteStaff: Function,
    resetPassword: Function,
  },
  components: {
    MessageForm,
  },

  data() {
    return {
      card_body_style: {
        width: '100%',
        height: '100%',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      showMessageDialog: false,
      messageTypeTab: 'sms',
    };
  },

  computed: {
    isMe() {
      return this.currentUser.id === this.staff.id;
    },

    staff_color() {
      const { representative_color } = this.staff.profile;
      return representative_color ? `#${representative_color}` : '#000';
    },

    staff_mobile() {
      const { contact_infos } = this.staff;
      return contact_infos.length ? contact_infos[0].contact : '';
    },

    isStaffAppLinked() {
      return !!this.staff.account_id;
    },

    isStaffAppDownloadLinked() {
      const isAccountId = !!this.staff.account_id;

      // 이미 연결되어 있음
      if (isAccountId) {
        return false;
      }

      const hasValidContactInfo = this.staff.contact_infos.some(
        contactInfo => contactInfo.type_id === 2 && !!contactInfo.contact,
      );

      const isMobile = !!this.staff.mobile;

      // 1. accunt_id 가 없고 contact_infos 의 밑 배열들중에 type = 2 인것 중에 contact가 있거나
      // 2. accunt_id 가 없고 mobile 항목이 null 이 아닐때
      return hasValidContactInfo || isMobile;
    },
    canSendAnyMessage() {
      return this.canSendSmsMessage;
    },

    studioName() {
      return this.$store.getters['studio/studio'].name;
    },

    defaultAppDownloadMessage() {
      return `[${this.studioName}] 안녕하세요, 강사님. 어플 다운로드 링크 보내드립니다. 관리자앱 회원가입 시 입력한 휴대폰 번호로 계정이 연결됩니다.\n\n안드로이드 - https://play.google.com/store/apps/details?id=kr.studiomate.manager&hl=ko\n아이폰 - https://apps.apple.com/kr/app/스튜디오메이트-관리자용/id1452473786`;
    },
  },

  methods: {
    handleClickCard() {
      if (this.isMe) {
        this.$router.push('/staffs/me');
      } else {
        this.$router.push(`/staffs/detail?id=${this.staff.id}`);
      }
    },
    handleAccountAction() {
      this.resetPassword(this.staff);
    },
    handleMessageTab(tab) {
      this.messageTypeTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 224px;
  height: 326px;
  border-radius: 3px;
  background-color: #ffffff;

  &__contents {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px 16px 16px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &__tags {
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        object-fit: contain;
        border-radius: 2px;
        background-color: #e6e6e6;
        font-size: 10px;
        line-height: 1;
        padding: 4px;
        color: $charcoal-grey;

        &.staff-card__contents__tags__no-role {
          background-color: $color-danger;
          color: #fff;
        }

        &.staff-card__contents__tags__me {
          background-color: $aqua-marine;
          color: #fff;
        }
      }
    }

    &__image {
      width: 90px;
      height: 90px;
      box-shadow: 0 8px 12px -8px rgba(52, 58, 64, 0.2);
      border-radius: 50%;
      margin: 10px 0 15px;
    }

    &__name {
      @include ellipsis;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.5;
      color: $charcoal-grey;
      margin-bottom: 8px;
      width: 100%;
      text-align: center;
    }

    &__mobile {
      opacity: 0.7;
      font-size: 12px;
      font-weight: 500;
      color: $charcoal-grey;
      margin-bottom: 8px;
    }

    &__account {
      margin-top: 10px;

      p {
        grid-row: 1;
        font-size: 12px;
        color: $charcoal-grey;
        margin-bottom: 12px;
      }

      button {
        grid-row: 2;
        width: 86px;
        height: 24px;
        font-size: 10px;
        line-height: 1;
        border: 1px solid $deep-sky-blue;
        border-radius: 2px;
        color: $deep-sky-blue;
        transition: 0.15s;
      }
    }

    &__applink {
      color: #76aef9;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
    }

    &__appdownload {
      margin-top: 8px;
      p {
        color: $charcoal-grey;
        font-size: 10px;
        font-weight: 500;
        opacity: 0.6;
      }

      button {
        grid-row: 2;
        width: 86px;
        height: 24px;
        border: 1px solid rgba(52, 58, 64, 0.4);
        border-radius: 1px;

        &:disabled {
          border-radius: 1px;
          border: 1px solid rgba(52, 58, 64, 0.4);
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }

  &__buttons {
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    padding: 8px 15px 7px;
    border-radius: 2px;
    background-color: rgba(#ebebeb, 0.3);
    min-height: 39px;

    button {
      width: 43px;
      height: 24px;
      border-radius: 2px;
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      transition: 0.15s;

      & + button {
        margin-left: 8px;
      }
    }

    &__delete {
      background: $color-danger;

      &:hover {
        background: #f64343;
      }
    }

    &__edit {
      background: $deep-sky-blue;

      &:hover {
        background: #1a87ff;
      }
    }
  }
}
.message-modal {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px 20px;
  }

  &__tabs {
    justify-content: flex-start;
    padding-bottom: 30px;
  }
}
</style>
