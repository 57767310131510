<template>
  <MainLayout :padded="false">
    <ContentHeader :breadcrumb="breadcrumb" :title="title" :contentMaxWidth="1024" />
    <section class="lecture-edit">
      <form-block :index="1" label="수업명" class="lecture-edit__title">
        <TextInput v-model="lectureTitle" :placeholder="defaultLectureTitle" material />
      </form-block>

      <form-block :index="2" label="담당 강사" class="lecture-edit__staff-select">
        <el-select v-model="instructorId" filterable placeholder="강사 선택">
          <el-option v-for="instructor in instructors" :key="instructor.id" :value="instructor.id" :label="instructor.name" />
        </el-select>
      </form-block>

      <form-block :index="3" label="수업 일정" flexRow>
        <div class="lecture-edit__datetime">
          <label>수업 일자</label>
          <el-date-picker
            v-model="date"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            :disabled="isPast"
            required
          />
        </div>
        <div class="lecture-edit__datetime">
          <hours-input
            v-model="time"
            startLabel="수업 시작"
            :startHourOptions="{
              start: '00:00',
              end: '23:50',
              step: '00:05',
            }"
            endLabel="수업 종료"
            :endHourOptions="{
              start: time.start || '00:00',
              end: '23:55',
              step: '00:05',
              minTime: time.start,
            }"
            :disabled="isPast"
            :clearable="false"
          />
        </div>
        <el-button
          class="lecture-edit__datetime__button"
          :disabled="!time.start || isPast"
          size="small"
          type="info"
          @click="setEndTime(30)"
          >30분</el-button
        >
        <el-button
          class="lecture-edit__datetime__button"
          :disabled="!time.start || isPast"
          size="small"
          type="info"
          @click="setEndTime(50)"
          >50분</el-button
        >
        <el-button
          class="lecture-edit__datetime__button"
          :disabled="!time.start || isPast"
          size="small"
          type="info"
          @click="setEndTime(60)"
          >1시간</el-button
        >
        <p v-if="isPast" class="lecture-edit__alert">
          시작된 수업의 날짜와 시간은 수정할 수 없습니다.
        </p>
      </form-block>

      <form-block :index="4" label="룸 설정" class="lecture-edit__room">
        <el-select
          popper-class="lecture-edit__room__select"
          v-model="currentRoomId"
          placeholder="룸 설정 안함"
          :disabled="!useRooms"
          filterable
          clearable
        >
          <el-option v-for="room in rooms" :key="room.id" :value="room.id" :label="room.name" />
        </el-select>
      </form-block>

      <form-block :index="5" label="예약/취소 가능 시간">
        <div class="lecture-edit__deadlines">
          <label>예약 가능 시간</label>
          <div class="lecture-edit__group">
            <div class="lecture-edit__type-viewer">
              {{ viewRuleType(deadlines.bookingType) }}
            </div>
            <p>
              <el-date-picker
                v-if="showStartTimeForBooking"
                v-model="bookingStartDate"
                type="date"
                placeholder="제한없음"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :disabled="isPast"
                :picker-options="getStartDatePickerOptions('booking')"
                :clearable="false"
              />
              <el-time-select
                class="lecture-edit__date-center"
                v-if="showStartTimeForBooking"
                v-model="bookingStartTime"
                placeholder="제한없음"
                value-format="HH:mm"
                :disabled="isPast || !bookingStartDate"
                :picker-options="getStartTimePickerOptions('booking')"
                :clearable="false"
              />
              <span v-if="showStartTimeForBooking"> 부터 </span>
              <el-date-picker
                v-model="bookingEndDate"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :disabled="isPast"
                :picker-options="getEndDatePickerOptions('booking')"
                :clearable="false"
              />
              <el-time-select
                class="lecture-edit__date-center"
                v-model="bookingEndTime"
                value-format="HH:mm"
                :disabled="isPast || !bookingEndDate"
                :picker-options="getEndTimePickerOptions('booking')"
                :clearable="false"
              />
              <span> 까지</span>
            </p>
          </div>
          <div class="lecture-edit__deadlines">
            <label>취소 가능 시간</label>
            <div class="lecture-edit__group">
              <div class="lecture-edit__type-viewer">
                {{ viewRuleType(deadlines.bookingCancelType) }}
              </div>
              <p>
                <el-date-picker
                  v-if="showStartTimeForBookingCancel"
                  v-model="cancelStartDate"
                  type="date"
                  placeholder="제한없음"
                  format="yyyy. M. d."
                  value-format="yyyy-MM-dd"
                  :disabled="isPast"
                  :picker-options="getStartDatePickerOptions('cancel')"
                  :clearable="false"
                />
                <el-time-select
                  class="lecture-edit__date-center"
                  v-if="showStartTimeForBookingCancel"
                  v-model="cancelStartTime"
                  placeholder="제한없음"
                  value-format="HH:mm"
                  :disabled="isPast || !cancelStartDate"
                  :picker-options="getStartTimePickerOptions('cancel')"
                  :clearable="false"
                />
                <span v-if="showStartTimeForBookingCancel"> 부터 </span>
                <el-date-picker
                  v-model="cancelEndDate"
                  type="date"
                  format="yyyy. M. d."
                  value-format="yyyy-MM-dd"
                  :disabled="isPast"
                  :picker-options="getEndDatePickerOptions('cancel')"
                  :clearable="false"
                />
                <el-time-select
                  class="lecture-edit__date-center"
                  v-model="cancelEndTime"
                  value-format="HH:mm"
                  :disabled="isPast || !cancelEndDate"
                  :picker-options="getEndTimePickerOptions('cancel')"
                  :clearable="false"
                />
                <span> 까지</span>
              </p>
            </div>
          </div>
          <p v-if="isPast" class="lecture-edit__alert">
            시작된 수업의 예약/취소 가능 시간은 수정할 수 없습니다.
          </p>
        </div>
      </form-block>

      <form-block
        :index="6"
        label="댱일 예약 변경 / 폐강 시간 / 예약 대기 자동 예약 시간"
        class="lecture-edit__staff-select"
        v-if="courseType === 'G'"
      >
        <div>
          <div>
            <label>당일 예약 변경 가능 시간</label>
            <p>
              <el-date-picker
                v-model="bookingChangeEndDate"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :disabled="isPast"
                :picker-options="datePickerOptions"
                :clearable="false"
              />
              <el-time-select
                class="lecture-edit__date-center"
                v-model="bookingChangeEndTime"
                value-format="HH:mm"
                :disabled="isPast || !bookingChangeEndDate"
                :picker-options="getTimePickerOptions(bookingChangeEndDate)"
                :clearable="false"
              />
              <span> 까지</span>
            </p>
          </div>
          <div v-if="courseType === 'G' && !!minTrainee" class="lecture-edit__staff-close-time">
            <label>폐강 시간</label>
            <p>
              <el-date-picker
                v-model="autocloseDate"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :disabled="isPast"
                :picker-options="datePickerOptions"
                :clearable="false"
              />
              <el-time-select
                class="lecture-edit__date-center"
                v-model="autocloseTime"
                value-format="HH:mm"
                :disabled="isPast || !autocloseDate"
                :picker-options="getTimePickerOptions(autocloseDate)"
                :clearable="false"
              />
            </p>
          </div>
          <p v-if="isPast" class="lecture-edit__alert">
            시작된 수업의 당일 예약 변경/폐강 시간은 수정할 수 없습니다.
          </p>
          <div style="margin-top: 1rem">
            <label>예약대기 자동 예약 시간</label>
            <p>
              <el-date-picker
                v-model="bookingAutoShiftAvailableMinutesFromStartDate"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :disabled="isPast"
                :picker-options="datePickerOptions"
                :clearable="false"
              />
              <el-time-select
                class="lecture-edit__date-center"
                v-model="bookingAutoShiftAvailableMinutesFromStartTime"
                value-format="HH:mm"
                :disabled="isPast || !bookingAutoShiftAvailableMinutesFromStartDate"
                :picker-options="getTimePickerOptions(bookingAutoShiftAvailableMinutesFromStartDate)"
                :clearable="false"
              />
              <span> 까지</span>
            </p>
          </div>
          <p v-if="isPast" class="lecture-edit__alert">
            시작된 수업의 예약대기 자동 예약 시간은 수정할 수 없습니다.
          </p>
        </div>
      </form-block>

      <form-block v-if="lecture && lecture.course.type === 'G'" :index="7" label="수강 인원">
        <div class="lecture-edit__trainees">
          <div>
            <label>최대 수강 인원</label>
            <NumberInput ref="maxTrainee" v-model="maxTrainee" :min="1" :max="255" :disabled="isPast" />
          </div>
          <div>
            <label>최소 수강 인원</label>
            <NumberInput ref="minTrainee" v-model="minTrainee" :min="0" :max="maxTrainee" :disabled="isPast" />
          </div>
        </div>
        <p v-if="!isPast" class="lecture-edit__alert">
          '폐강 시간' 이후, 최소 수강 인원이 채워지지 않으면 이 수업은 자동 삭제 됩니다.<br />
          단, 최소 수강 인원이 0명인 경우는 삭제되지 않습니다.
        </p>
        <p v-if="isPast" class="lecture-edit__alert">
          시작된 수업의 최소 수강 인원은 수정할 수 없습니다.
        </p>
      </form-block>

      <form-block
        v-if="lecture && lecture.course.type === 'G' && studioPolicies.weekly_waiting_limit"
        :index="8"
        label="예약 대기 가능 인원"
      >
        <div class="lecture-edit__trainees">
          <NumberInput ref="waitingTraineeLimit" v-model="waitingTraineeLimit" :min="0" :max="99" />
        </div>
        <p class="lecture-edit__info">
          0을 입력할 경우, 예약대기 인원 수에는 제한이 없습니다.
        </p>
      </form-block>

      <form-block
        v-if="lecture && (studio.grade === 2 || studio.usable_enter) && studioPolicies.is_enter"
        :index="lecture && lecture.course.type === 'G' ? (studioPolicies.weekly_waiting_limit ? 9 : 8) : 6"
        label="체크인 가능 시간"
      >
        <div class="lecture-edit__deadlines">
          <div class="lecture-edit__group">
            <p>
              <el-date-picker
                v-model="enterStartDateDeadline"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :picker-options="getEnterDateMaxTimePickerOptions()"
                :clearable="false"
                :disabled="isPast"
              />
              <el-time-select
                class="lecture-edit__date-center"
                v-model="enterStartDeadline"
                value-format="HH:mm"
                :picker-options="checkinTimeOptions(this.enterStartDateDeadline)"
                :clearable="false"
                :disabled="isPast"
              />
              <span class="range"> ~ </span>

              <el-date-picker
                v-model="enterEndDateDeadline"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :disabled="!enterStartDateDeadline || isPast"
                :picker-options="getEnterDateMaxTimePickerOptions()"
                :clearable="false"
              />
              <el-time-select
                class="lecture-edit__date-center"
                v-model="enterEndDeadline"
                value-format="HH:mm"
                :picker-options="checkinTimeOptions(this.enterEndDateDeadline)"
                :clearable="false"
                :disabled="isPast"
              />
              <span> 까지</span>
            </p>
          </div>
        </div>
        <p v-if="isPast" class="lecture-edit__alert">
          시작된 수업의 체크인 가능 시간은 수정할 수 없습니다.
        </p>
      </form-block>

      <!-- 예약 필수 수업 체크 -->
      <form-block
        v-if="lecture && lecture.course.type === 'G' && (studio.grade === 2 || studio.usable_enter) && studioPolicies.is_enter"
        :index="studioPolicies.weekly_waiting_limit ? 10 : 9"
        label="예약 필수 수업"
      >
        <el-checkbox v-model="isBookingOnly" :true-label="1" :false-label="0" label="사용함" slot="checkbox"></el-checkbox>
      </form-block>
    </section>

    <bottom-action-bar :message="lastUpdatedAt">
      <el-button v-loading="isSaving" :disabled="isSaving" @click="saveLesson">수업 수정 완료</el-button>
    </bottom-action-bar>
  </MainLayout>
</template>

<script>
export default {
  data() {
    return {
      isSaving: false,
      isPast: false,
      instructors: [],
      rooms: [],
      currentRoomName: '',

      /** 수업데이터 */
      lecture: null,
      lectureTitle: '',
      instructorId: null,
      roomId: null,
      date: this.moment(0).format('YYYY-MM-DD'),
      time: {
        start: this.moment()
          .startOf('hour')
          .format('HH:mm'),
        end: this.moment()
          .startOf('hour')
          .add(30, 'minutes')
          .format('HH:mm'),
      },
      maxTrainee: 1,
      minTrainee: 0,
      deadlines: {
        booking: { start_at: null, end_at: null },
        cancel: { start_at: null, end_at: null },
        bookingChange: { start_at: null, end_at: null },
        autoclose: null,
        booking_auto_shift_available_minutes_from_start: null,
      },
      waitingTraineeLimit: 0,
      isBookingOnly: 1,
      setEnterStartDeadline: null,
      setEnterEndDeadline: null,
    };
  },

  computed: {
    currentRoomId: {
      get() {
        return this.roomId && !this.rooms.some(room => room.id === this.roomId) ? this.currentRoomName : this.roomId;
      },
      set(value) {
        if (!value) {
          this.roomId = null;
        }
        this.roomId = value;
      },
    },
    studio() {
      return this.$store.getters['studio/studio'];
    },

    breadcrumb() {
      return {
        parent: { path: '/schedule', label: '일정' },
        current_route_label: this.title,
      };
    },

    title() {
      if (!this.lecture) return '';
      return this.courseType === 'P' ? '프라이빗 수업 수정하기' : '그룹 수업 수정하기';
    },

    courseType() {
      return _.get(this.lecture, 'course.type');
    },
    courseTitle() {
      return _.get(this.lecture, 'course.title');
    },

    defaultLectureTitle() {
      const firstBooking = _.get(this.lecture, 'bookings[0]');

      if (this.courseType === 'G') {
        return this.courseTitle;
      } else if (firstBooking) {
        return this.maxTrainee === 1
          ? `${firstBooking.member.profile.name}님`
          : `${firstBooking.member.profile.name} 외 ${this.maxTrainee - 1}명`;
      }

      return '';
    },

    showStartTimeForBooking() {
      return this.deadlines.bookingType && this.deadlines.bookingType[1] === 'R';
    },
    showStartTimeForBookingCancel() {
      return this.deadlines.bookingCancelType && this.deadlines.bookingCancelType[1] === 'R';
    },

    bookingStartDate: {
      get() {
        if (!this.deadlines.booking.start_at) return null;
        return this.moment(this.deadlines.booking.start_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        const start_at = `${value} ${this.bookingStartTime || '00:00'}:00`;
        const { end_at } = this.deadlines.booking;
        this.deadlines.booking.start_at = start_at > end_at ? end_at : start_at;
      },
    },

    bookingStartTime: {
      get() {
        if (!this.deadlines.booking.start_at) return null;
        return this.moment(this.deadlines.booking.start_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        const start_at = `${this.bookingStartDate} ${value}:00`;
        const { end_at } = this.deadlines.booking;
        this.deadlines.booking.start_at = start_at > end_at ? end_at : start_at;
      },
    },

    bookingEndDate: {
      get() {
        if (!this.deadlines.booking.end_at) return null;
        return this.moment(this.deadlines.booking.end_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        const { start_at } = this.deadlines.booking;
        const end_at = `${value} ${this.bookingEndTime || '00:00'}:00`;
        this.deadlines.booking.end_at = start_at > end_at ? start_at : end_at;
      },
    },

    bookingEndTime: {
      get() {
        if (!this.deadlines.booking.end_at) return null;
        return this.moment(this.deadlines.booking.end_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        const { start_at } = this.deadlines.booking;
        const end_at = `${this.bookingEndDate} ${value}:00`;
        this.deadlines.booking.end_at = start_at > end_at ? start_at : end_at;
      },
    },

    cancelStartDate: {
      get() {
        if (!this.deadlines.cancel.start_at) return null;
        return this.moment(this.deadlines.cancel.start_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        const start_at = `${value} ${this.cancelStartTime || '00:00'}:00`;
        const { end_at } = this.deadlines.cancel;
        this.deadlines.cancel.start_at = start_at > end_at ? end_at : start_at;
      },
    },

    cancelStartTime: {
      get() {
        if (!this.deadlines.cancel.start_at) return null;
        return this.moment(this.deadlines.cancel.start_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        const start_at = `${this.cancelStartDate} ${value}:00`;
        const { end_at } = this.deadlines.cancel;
        this.deadlines.cancel.start_at = start_at > end_at ? end_at : start_at;
      },
    },

    cancelEndDate: {
      get() {
        if (!this.deadlines.cancel.end_at) return null;
        return this.moment(this.deadlines.cancel.end_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        const { start_at } = this.deadlines.cancel;
        const end_at = `${value} ${this.cancelEndTime || '00:00'}:00`;
        this.deadlines.cancel.end_at = start_at > end_at ? start_at : end_at;
      },
    },

    cancelEndTime: {
      get() {
        if (!this.deadlines.cancel.end_at) return null;
        return this.moment(this.deadlines.cancel.end_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        const { start_at } = this.deadlines.cancel;
        const end_at = `${this.cancelEndDate} ${value}:00`;
        this.deadlines.cancel.end_at = start_at > end_at ? start_at : end_at;
      },
    },

    bookingChangeEndDate: {
      get() {
        if (!this.deadlines.bookingChange.end_at) return null;
        return this.moment(this.deadlines.bookingChange.end_at).format('YYYY-MM-DD');
      },
      set(value) {
        this.deadlines.bookingChange.end_at = !value
          ? this.lecture.start_on
          : `${value} ${this.bookingChangeEndTime || '00:00'}:00`;
      },
    },

    bookingChangeEndTime: {
      get() {
        if (!this.deadlines.bookingChange.end_at) return null;
        return this.moment(this.deadlines.bookingChange.end_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.deadlines.bookingChange.end_at = `${this.bookingChangeEndDate} ${value}:00`;
      },
    },

    bookingAutoShiftAvailableMinutesFromStartDate: {
      get() {
        if (!this.deadlines.booking_auto_shift_available_minutes_from_start) return null;
        return this.moment(this.deadlines.booking_auto_shift_available_minutes_from_start).format('YYYY-MM-DD');
      },
      set(value) {
        this.deadlines.booking_auto_shift_available_minutes_from_start = !value
          ? this.lecture.start_on
          : `${value} ${this.bookingAutoShiftAvailableMinutesFromStartTime || '00:00'}:00`;
      },
    },

    bookingAutoShiftAvailableMinutesFromStartTime: {
      get() {
        if (!this.deadlines.booking_auto_shift_available_minutes_from_start) return null;
        return this.moment(this.deadlines.booking_auto_shift_available_minutes_from_start).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.deadlines.booking_auto_shift_available_minutes_from_start = `${this.bookingAutoShiftAvailableMinutesFromStartDate} ${value}:00`;
      },
    },

    autocloseDate: {
      get() {
        if (!this.deadlines.autoclose) return null;
        return this.moment(this.deadlines.autoclose).format('YYYY-MM-DD');
      },
      set(value) {
        this.deadlines.autoclose = !value ? this.lecture.start_on : `${value} ${this.autocloseTime || '00:00'}:00`;
      },
    },

    autocloseTime: {
      get() {
        if (!this.deadlines.autoclose) return null;
        return this.moment(this.deadlines.autoclose).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.deadlines.autoclose = `${this.autocloseDate} ${value}:00`;
      },
    },

    datePickerOptions() {
      if (!this.lecture) return null;
      const { moment, lecture } = this;

      return {
        disabledDate(date) {
          return moment(date).format('YYYYMMDD') > moment(lecture.start_on).format('YYYYMMDD');
        },
      };
    },

    checkinMinTotal() {
      //  체크인 min total
      return this.moment(`${this.date} ${this.time.start}:00`)
        .subtract(360, 'minute')
        .format('YYYY-MM-DD HH:mm');
    },

    checkinMaxTotal() {
      // 현재 체크인 max total
      return this.moment(`${this.date} ${this.time.end}:00`)
        .add(360, 'minute')
        .format('YYYY-MM-DD HH:mm');
    },

    enterStartDateDeadline: {
      //체크인 시작 날짜
      get() {
        const dataTime = this.moment(this.setEnterStartDeadline).format('YYYYMMDD');

        return dataTime;
      },
      set(value) {
        if (!value) return;
        this.getEnterDateMaxTimePickerOptions();
        this.setEnterStartDeadline = `${value} ${this.enterStartDeadline}:00`;
        this.setMinMaxCheckin(value, 'start');
      },
    },

    enterEndDateDeadline: {
      //체크인 종료 날짜
      get() {
        return this.moment(this.setEnterEndDeadline).format('YYYYMMDD');
      },
      set(value) {
        if (!value) return;
        this.getEnterDateMaxTimePickerOptions();
        this.setEnterEndDeadline = `${value} ${this.enterEndDeadline}:00`;
        this.setMinMaxCheckin(value, 'end');
      },
    },

    enterStartDeadline: {
      //체크인 시작 시간
      get() {
        if (this.setEnterStartDeadline < this.checkinMinTotal) {
          return this.moment(this.checkinMinTotal).format('HH:mm');
        } else if (this.setEnterStartDeadline > this.checkinMaxTotal) {
          return this.moment(this.checkinMaxTotal).format('HH:mm');
        }

        return this.moment(this.setEnterStartDeadline).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.checkinTimeOptions(this.setEnterStartDeadline);
        this.setEnterStartDeadline = `${this.moment(this.enterStartDateDeadline).format('YYYY-MM-DD')} ${value}:00`;
      },
    },

    enterEndDeadline: {
      //체크인 종료 시간
      get() {
        if (this.setEnterEndDeadline < this.checkinMinTotal) {
          return this.moment(this.checkinMinTotal).format('HH:mm');
        } else if (this.setEnterEndDeadline > this.checkinMaxTotal) {
          return this.moment(this.checkinMaxTotal).format('HH:mm');
        }

        return this.moment(this.setEnterEndDeadline).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.checkinTimeOptions(this.enterEndDateDeadline);
        this.setEnterEndDeadline = `${this.moment(this.enterEndDateDeadline).format('YYYY-MM-DD')} ${value}:00`;
      },
    },

    lastUpdatedAt() {
      if (!this.lecture) return null;
      const date = this.lecture.updated_at || this.lecture.created_at;
      if (!date) return null;

      const suffix = this.lecture.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },

    updated() {
      if (!this.lecture) return {};
      const updatedData = {
        title: this.lectureTitle,
        instructor_id: this.instructorId,
        start_on: `${this.date} ${this.time.start}:00`,
        end_on: `${this.date} ${this.time.end}:00`,
        max_trainee: this.maxTrainee,
        min_trainee: this.minTrainee,
        waiting_trainee_limit: this.waitingTraineeLimit,
        room_id: this.roomId,
        booking_start_at: this.deadlines.booking.start_at,
        booking_end_at: this.deadlines.booking.end_at,
        booking_cancel_start_at: this.deadlines.cancel.start_at,
        booking_cancel_end_at: this.deadlines.cancel.end_at,
        daily_change_booking_end_at: this.deadlines.bookingChange.end_at,
        autoclose_at: this.deadlines.autoclose,
        is_booking_only: this.isBookingOnly,
        enter_start_at: `${this.setEnterStartDeadline}`,
        enter_end_at: `${this.setEnterEndDeadline}`,
        booking_auto_shift_available_minutes_from_start: this.deadlines.booking_auto_shift_available_minutes_from_start,
      };
      return updatedData;
    },

    updatedFields() {
      if (!this.lecture || !this.updated) return [];
      const updatedKey = Object.keys(this.updated).filter(key => this.lecture[key] !== this.updated[key]);
      return updatedKey;
    },

    useRooms() {
      return _.get(this.studioPolicies, 'is_use_rooms');
    },
  },

  watch: {
    date: 'getEmptyRooms',
    time: 'getEmptyRooms',
    maxTrainee(newValue) {
      if (newValue > 255) {
        this.maxTrainee = 255;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!from.query.id) return next('/schedule');
    next();
  },

  async created() {
    const { moment } = this;
    /** 강사 목록 */
    try {
      const res = await this.$api.staff.getAll();
      this.instructors = res.data.staffs.map(staff => ({
        id: staff.id,
        name: staff.profile.name,
      }));
    } catch (error) {
      this.instructors = [];
    }

    try {
      const res = await this.$api.lecture.get(this.$route.query.id);
      this.lecture = res.data.lecture;

      this.lectureTitle = this.lecture.title || this.lecture.course.title;
      this.instructorId = this.lecture.instructor_id;
      if (!this.instructors.some(({ id }) => id == this.instructorId)) this.instructorId = null;
      this.date = moment(this.lecture.start_on).format('YYYY-MM-DD');
      this.time.start = moment(this.lecture.start_on).format('HH:mm');
      this.time.end = moment(this.lecture.end_on).format('HH:mm');
      this.maxTrainee = this.lecture.max_trainee;
      this.minTrainee = this.lecture.min_trainee;
      this.waitingTraineeLimit = this.lecture.waiting_trainee_limit;
      this.roomId = this.lecture.room_id;
      this.currentRoomName = this.lecture.room?.name || '';
      this.isPast = moment(this.lecture.start_on).isBefore(moment());
      this.deadlines = {
        booking: {
          start_at: this.lecture.booking_start_at,
          end_at: this.lecture.booking_end_at,
        },
        cancel: {
          start_at: this.lecture.booking_cancel_start_at,
          end_at: this.lecture.booking_cancel_end_at,
        },
        bookingChange: {
          end_at: this.lecture.daily_change_booking_end_at,
        },
        autoclose: this.lecture.autoclose_at,
        bookingType: this.lecture.course.group_booking_rule_type,
        bookingCancelType: this.lecture.course.group_booking_cancel_rule_type,
        booking_auto_shift_available_minutes_from_start: this.lecture.booking_auto_shift_available_minutes_from_start,
      };

      if (!this.lecture.is_booking_only) {
        this.isBookingOnly = 1;
      }
      this.isBookingOnly = this.lecture.is_booking_only;

      if (!this.lecture.enter_start_at) {
        this.lecture.enter_start_at = this.lecture.start_on;
      }
      this.setEnterStartDeadline = this.lecture.enter_start_at;

      if (!this.lecture.enter_end_at) {
        this.lecture.enter_end_at = this.lecture.end_on;
      }
      this.setEnterEndDeadline = this.lecture.enter_end_at;
    } catch (error) {
      this.$utils.notify.parseError(this, error);
      this.$router.push('/schedule');
    }
  },

  methods: {
    setMinMaxCheckin(setDate, value) {
      const formating = () => {
        const times = this.setEnterStartDeadline < this.checkinMinTotal ? this.checkinMinTotal : this.checkinMaxTotal;
        return `${setDate} ${this.moment(times).format('HH:mm')}:00`;
      };

      value === 'start' ? (this.setEnterStartDeadline = formating()) : (this.setEnterEndDeadline = formating());
    },
    compareDate(value) {
      if (this.moment(this.checkinMinTotal).format('YYYY-MM-DD') === this.moment(this.checkinMaxTotal).format('YYYY-MM-DD'))
        return {
          //min, max 날짜가 하루일때
          minTime: this.moment(this.checkinMinTotal).format('HH:mm'),
          maxTime: this.moment(this.checkinMaxTotal).format('HH:mm'),
        };
      else if (this.moment(this.checkinMinTotal).format('YYYY-MM-DD') === this.moment(value).format('YYYY-MM-DD'))
        return {
          //min time 세팅
          minTime: this.moment(this.checkinMinTotal).format('HH:mm'),
          maxTime: null,
        };
      else if (this.moment(this.checkinMaxTotal).format('YYYY-MM-DD') === this.moment(value).format('YYYY-MM-DD'))
        return {
          //max time 세팅
          minTime: null,
          maxTime: this.moment(this.checkinMaxTotal).format('HH:mm'),
        };

      return { minTime: null, maxTime: null };
    },
    handleAvailableHoursChange(value) {
      this.lecture = { ...this.lecture, ...value };
    },
    getStartDatePickerOptions(key) {
      if (!this.lecture) return null;
      const { moment, lecture } = this;
      const { end_at } = _.get(this.deadlines, key);

      return {
        disabledDate(date) {
          const dateFormatted = moment(date).format('YYYYMMDD');
          return dateFormatted > moment(lecture.start_on).format('YYYYMMDD') || dateFormatted > moment(end_at).format('YYYYMMDD');
        },
      };
    },

    viewRuleType(type) {
      const labels = {
        R: '수업시간 기준 - 마감시간',
        RR: '수업시간 기준 - 시작시간부터 마감시간까지',
        A: '수업일 기준 - 마감시간',
        AR: '수업일 기준 - 시작시간부터 마감시간까지',
      };

      return labels[type];
    },

    getEndDatePickerOptions(key) {
      if (!this.lecture) return null;
      const { moment, lecture } = this;
      const { start_at } = _.get(this.deadlines, key);
      return {
        disabledDate(date) {
          const dateFormatted = moment(date).format('YYYYMMDD');
          return (
            dateFormatted > moment(lecture.start_on).format('YYYYMMDD') ||
            (!!start_at && dateFormatted < moment(start_at).format('YYYYMMDD'))
          );
        },
      };
    },
    getEnterDateMaxTimePickerOptions() {
      if (!this.lecture) return null;
      const { moment } = this;
      const start = this.moment(this.checkinMinTotal).format('YYYY-MM-DD');
      const end = this.moment(this.checkinMaxTotal).format('YYYY-MM-DD');
      return {
        disabledDate(date) {
          const dateFormatted = moment(date).format('YYYYMMDD');
          return dateFormatted < moment(start).format('YYYYMMDD') || dateFormatted > moment(end).format('YYYYMMDD');
        },
      };
    },
    checkinTimeOptions(value) {
      if (!this.lecture) {
        return { start: '00:00', end: '23:59', step: '00:01' };
      }
      const { minTime, maxTime } = this.compareDate(value);
      return {
        start: '00:00',
        end: '23:59',
        step: '00:01',
        minTime,
        maxTime,
      };
    },

    getStartTimePickerOptions(key) {
      if (!this.lecture) {
        return { start: '00:00', end: '23:59', step: '00:01' };
      }
      const { start_on } = this.lecture;
      const { start_at, end_at } = _.get(this.deadlines, key);

      const maxDatetime = end_at < start_on ? end_at : start_on;
      const maxDate = this.moment(maxDatetime).format('YYYY-MM-DD');
      const maxTime = this.moment(maxDatetime)
        .add(1, 'minute')
        .format('HH:mm');
      const startOn = this.moment(start_at).format('YYYY-MM-DD');

      return {
        start: '00:00',
        end: '23:59',
        step: '00:01',
        maxTime: maxDate === startOn ? maxTime : null,
      };
    },

    getEndTimePickerOptions(key) {
      if (!this.lecture) {
        return { start: '00:00', end: '23:59', step: '00:01' };
      }
      const { start_on } = this.lecture;
      const { start_at, end_at } = _.get(this.deadlines, key);

      const maxDate = this.moment(start_on).format('YYYY-MM-DD');
      const maxTime = this.moment(start_on)
        .add(1, 'minute')
        .format('HH:mm');
      const minDate = this.moment(start_at).format('YYYY-MM-DD');
      const minTime = this.moment(start_at)
        .add(1, 'minute')
        .format('HH:mm');
      const endOn = this.moment(end_at).format('YYYY-MM-DD');

      return {
        start: '00:00',
        end: '23:59',
        step: '00:01',
        minTime: minDate === endOn ? minTime : null,
        maxTime: maxDate === endOn ? maxTime : null,
      };
    },

    getTimePickerOptions(date) {
      if (!this.lecture) {
        return { start: '00:00', end: '23:55', step: '00:05' };
      }
      const { start_on } = this.lecture;
      const lectureStartOn = this.moment(start_on).format('YYYY-MM-DD');
      const lectureStartAt = this.moment(start_on)
        .add(1, 'minute')
        .format('HH:mm');
      const maxTime = lectureStartOn === date ? lectureStartAt : null;

      return {
        start: '00:00',
        end: '23:55',
        step: '00:05',
        maxTime,
      };
    },

    setEndTime(duration) {
      const [hour, minute] = this.time.start.split(':');
      const endTime = this.moment()
        .hour(hour)
        .minute(minute)
        .add(duration, 'minutes')
        .format('HH:mm');
      this.time = {
        start: this.time.start,
        end: endTime.slice(0, 2) === '00' ? '23:59' : endTime,
      };
    },

    /** 빈방 가져오기 */
    async getEmptyRooms() {
      if (!this.date || !this.time.start || !this.time.end) return;

      try {
        const params = {
          start_date: `${this.date} ${this.time.start}`,
          end_date: `${this.date} ${this.time.end}`,
          lecture_id: this.lecture.id,
        };
        const res = await this.$api.studio.rooms.getEmpty(params);
        this.rooms = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    /** 수정 권한 체크 */
    checkPermission(lecture) {
      if (!this.canUpdateLecture(lecture.course.type, lecture.instructor_id)) {
        throw new Error('권한이 없습니다.');
      }
    },

    validate() {
      if (!this.instructorId) {
        this.$utils.notify.error(this, '오류', '담당강사를 선택해주세요.');
        return false;
      } else if (!this.date || !this.time.start || !this.time.end) {
        this.$utils.notify.error(this, '오류', '수업 일정을 입력해주세요.');
        return false;
      } else if (isNaN(this.maxTrainee)) {
        this.$utils.notify.error(this, '오류', '최대 수강 인원을 입력해주세요.');
        this.$refs.maxTrainee.$el.querySelector('input').focus();
        return false;
      } else if (isNaN(this.minTrainee)) {
        this.$utils.notify.error(this, '오류', '최소 수강 인원을 입력해주세요.');
        this.$refs.minTrainee.$el.querySelector('input').focus();
        return false;
      } else if (isNaN(this.waitingTraineeLimit)) {
        this.$utils.notify.error(this, '오류', '예약 대기 가능 인원을 입력해주세요.');
        this.$refs.waitingTraineeLimit.$el.querySelector('input').focus();
        return false;
      } else if (this.deadlines.booking.start_at === this.deadlines.booking.end_at) {
        this.$utils.notify.error(this, '예약 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
        return false;
      } else if (this.deadlines.cancel.start_at === this.deadlines.cancel.end_at) {
        this.$utils.notify.error(this, '예약 취소 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
        return false;
      } else if (!this.updatedFields.length) {
        this.redirectToLectureDetail();
        return false;
      }

      return true;
    },

    async saveLesson() {
      if (!this.validate()) return;

      /** 룸 설정 확인 */
      if (this.useRooms && !this.roomId) {
        const title = '룸 설정';
        const message = '룸이 설정되지 않았습니다.<br>계속 하시겠습니까?';
        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (!proceed) return;
      }

      this.isSaving = true;
      /** 최소 수강 인원 확인 */
      if (this.minTrainee !== 0 && this.courseType === 'G') {
        const title = '최소 수강 인원 확인';
        const message = `최소 수강 인원 (${this.minTrainee}명) 이 채워지지 않으면 이 수업은 자동으로 삭제되고 예약은 자동 취소됩니다.`;

        const checkMinTrainee = await this.$confirm(message, title, {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (!checkMinTrainee) {
          this.isSaving = false;
          return this.$refs.minTrainee.$el.querySelector('.el-input__inner').focus();
        }
      }

      let lecture = { id: this.lecture.id };
      this.updatedFields.forEach(key => (lecture[key] = this.updated[key]));
      lecture = this.$utils.replaceNullWithMinusOne(lecture);
      try {
        await this.$api.lecture.update(lecture);
        this.$utils.notify.success(this, '성공', '수정되었습니다.');
        this.redirectToLectureDetail();
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isSaving = false;
      }
    },

    redirectToLectureDetail() {
      this.$router.push(`/lecture/detail?id=${this.lecture.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-edit {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 10px 10px 60px;

  @include mq(768px) {
    padding: 30px 30px 60px;
  }

  &__title {
    .custom-text-input {
      max-width: 200px;
    }
  }

  &__staff-close-time {
    margin-top: 10px;
  }

  &__staff-select,
  &__room {
    /deep/ .el-select {
      width: 100%;
      max-width: 200px;
    }
  }

  &__datetime {
    @include flex(column);
    margin-top: 10px;

    label {
      font-size: 12px;
      font-weight: 500;
    }

    .hours-input {
      padding: 0;

      @include mq(768px) {
        padding: 0 20px;
      }
    }

    &__button {
      align-self: center;
      margin-top: 10px;
    }
  }

  &__deadlines {
    @include flex(column);

    & > div {
      margin-top: 10px;
    }

    label {
      font-size: 12px;
      font-weight: 500;
    }

    &__rule-type {
      background: $deep-sky-blue;
      border-radius: 4px;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 16px;
      padding: 7px 14px;
    }
  }

  &__trainees {
    @include flex(row, center, flex-start);

    & > div {
      @include flex(column);
      width: 50%;
      max-width: 150px;

      & + div {
        margin-left: 20px;
      }

      label {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  &__alert {
    color: $color-danger;
    font-size: 12px;
    font-weight: bold;
    margin-top: 12px;
    width: 100%;
  }

  &__info {
    color: $dodger-blue;
    font-size: 12px;
    font-weight: bold;
    margin-top: 12px;
  }

  &__group {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    .range {
      display: inline-block;
      padding: 0 15px;
    }
  }

  &__type-viewer {
    margin-right: 10px;
    width: 238px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    height: 32px;
    width: 238px;
    text-align: center;
    font-size: 12px;
    color: $gray-500;
    line-height: 32px;

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 32px;
      width: 238px;
      padding: 0 15px 0 0;
      text-align: center;
      font-size: 12px;
      color: $gray-500;
      &:hover {
        color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }

  &__date-center {
    /deep/ .el-input__inner {
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
.lecture-edit__room__select {
  // 리뉴얼 관리자 앱에서 룸중복 되게 수업 수정이 가능하게 됨
  // 다른 수업과 룸이 중복된 수업을 수정 시 option list 에 해당 룸이 없어(중복되지 않는 빈방 리스트만 호출함) '맞지 않는 데이터입니다.' 표시가 나와서 노출되지 않도록 스타일 추가
  .el-select-dropdown__empty {
    display: none;
  }
}
</style>
