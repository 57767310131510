/**
 * 이미지 URL 생성
 *
 * @param {Object} image ex) image
 * @param {String} size ex) 32
 * @param {String} defaultImg ex) dummy_profile_90x90.png
 */

export default (image, size, defaultImage) => {
  const dummyProfileImage = require('@/assets/images/dummy_profile_90x90.png');
  try {
    // 백엔드에서 아바타가 없을 땐 []로 반환하도록 많은 api에서 설정되어 있음. 따로 건들지 않고 프론트에서 대응
    if (!image || (Array.isArray(image) && !image.length)) return dummyProfileImage;

    if (typeof image === 'string') return image;
    const { host, name, path } = image;
    return `${host}${path}/${name}?w=${size}&h=${size}&f=webp&q=80`;
  } catch (error) {
    return defaultImage || dummyProfileImage;
  }
};
