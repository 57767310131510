<template>
  <section class="message-form" v-loading="loadingMessage">
    <!-- <div v-if="!isOnModal" class="message-form__point-info">
      <div class="message-form__point-info__infoPoint">
        <p>앱 푸시 메시지 건당 <b>2포인트</b> / 500자 제한</p>
      </div>
      <div>|</div>
      <div class="message-form__point-info__myPoint">
        잔여 {{ point | comma }} 포인트
        <BaseButton round @click="$router.push('/settings/payments')">포인트 충전</BaseButton>
      </div>
    </div> -->
    <div class="message-form__input" :class="{ 'has-button': !hideMemberListButton }">
      <TextInput
        v-model="membersSelected"
        :label="isStaff ? '강사' : '회원'"
        labelAlign="row"
        labelWidth="60px"
        size="large"
        :placeholder="isStaff ? '강사를 선택해주세요.' : '회원 선택해주세요.'"
        :error="$v.studio_message.targets.$dirty && !$v.studio_message.targets.required"
        readonly
        required
      />
      <PlainButton v-if="!hideMemberListButton" slot="left" size="large" @click="showMemberSelectDialog = true">
        회원목록
      </PlainButton>
    </div>

    <div class="message-form__input">
      <TextInput
        v-model="studio_message.title"
        ref="title"
        label="제목"
        labelAlign="row"
        labelWidth="60px"
        size="large"
        :placeholder="placeholder || '제목을 입력해주세요.'"
        :error="$v.studio_message.title.$dirty && !$v.studio_message.title.required"
        required
      />
    </div>

    <div class="message-form__input">
      <Textarea
        v-model="studio_message.body"
        :rows="7"
        :maxlength="messageType === 'message' ? 500 : null"
        size="large"
        placeholder="메시지를 입력해주세요."
        :error="$v.studio_message.body.$dirty && !$v.studio_message.body.required"
        :height="180"
        required
      />
    </div>

    <div class="message-form__helpers">
      <p>
        <span v-if="studio_message.send_on" class="message-form__helpers__send-on">
          {{ moment(studio_message.send_on).format('YYYY. M. D. (ddd) HH:mm') }}
        </span>
        <el-tag size="mini">
          {{ !studio_message.send_on ? '즉시 발송' : '보내기 예약' }}
        </el-tag>
      </p>
      <p v-if="messageType === 'message'">{{ studio_message.body.length }} / 500자</p>
      <p v-else>
        <span :class="[checkByte > 2000 ? 'message-form__helpers__over-2000byte' : null]">{{ checkByte }}</span> /
        {{ lmsCheck ? '2000 바이트' : '90 바이트' }}
      </p>
    </div>

    <div v-if="isOnModal" class="message-form__modal-buttons">
      <el-popover slot="left" placement="top-start" trigger="manual" v-model="showSendOnPopover">
        <div class="message-form__send-on">
          <div class="message-form__send-on__header">
            <h4>보내기 예약</h4>
            <CircleButton @click="showSendOnPopover = false">
              <i class="el-icon-close"></i>
            </CircleButton>
          </div>
          <div class="message-form__send-on__body">
            <p>
              즉시발송하는 경우에는 비워두고, 발송예약하려면 날짜와 시간을 지정해주세요.
            </p>
            <label>예약시간</label>
            <div>
              <el-date-picker
                v-model="sendOnDate"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :picker-options="sendOnDatePickerOptions"
                required
              />
              <el-time-select
                v-model="sendOnTime"
                value-format="HH:mm"
                :disabled="!sendOnDate"
                :picker-options="sendOnTimePickerOptions"
              />
            </div>
            <span>
              {{ sendOnError ? '예약발송은 최소 10분후 부터 가능합니다.' : '' }}
            </span>
          </div>
          <div class="message-form__send-on__footer">
            <BaseButton type="info" size="large" @click="showSendOnPopover = false">
              취소
            </BaseButton>
            <BaseButton size="large" @click="setSendOn">
              확인
            </BaseButton>
          </div>
        </div>
        <PlainButton slot="reference" size="large" @click="toggleSendOnPopover">
          보내기 예약
        </PlainButton>
      </el-popover>
      <div v-if="checkByte > 90 && messageType === 'sms'" class="lms-warning">
        LMS로 전송되며 회원당 37포인트가 차감됩니다.
      </div>
      <BaseButton
        v-loading="isSendingMessage"
        size="large"
        :disabled="isSendingMessage || titleCheckByte > 40 || (messageType === 'sms' && checkByte > 2000)"
        @click="sendAfterValidation"
        >보내기</BaseButton
      >
    </div>

    <BottomActionBar v-else contentMaxWidth="944px" hideButtonBack>
      <el-popover slot="left" placement="top-start" trigger="manual" v-model="showSendOnPopover">
        <div class="message-form__send-on">
          <div class="message-form__send-on__header">
            <h4>보내기 예약</h4>
            <CircleButton @click="showSendOnPopover = false">
              <i class="el-icon-close"></i>
            </CircleButton>
          </div>
          <div class="message-form__send-on__body">
            <p>
              즉시발송하는 경우에는 비워두고, 발송예약하려면 날짜와 시간을 지정해주세요.
            </p>
            <label>예약시간</label>
            <div>
              <el-date-picker
                v-model="sendOnDate"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :picker-options="sendOnDatePickerOptions"
                required
              />
              <el-time-select
                v-model="sendOnTime"
                value-format="HH:mm"
                :disabled="!sendOnDate"
                :picker-options="sendOnTimePickerOptions"
              />
            </div>
            <span>
              {{ sendOnError ? '예약발송은 최소 10분후 부터 가능합니다.' : '' }}
            </span>
          </div>
          <div class="message-form__send-on__footer">
            <BaseButton type="info" size="large" @click="showSendOnPopover = false">
              취소
            </BaseButton>
            <BaseButton size="large" @click="setSendOn">
              확인
            </BaseButton>
          </div>
        </div>
        <PlainButton slot="reference" type="white" size="large" @click="toggleSendOnPopover">
          보내기 예약
        </PlainButton>
      </el-popover>
      <BaseButton
        v-loading="isSendingMessage"
        type="white"
        size="large"
        :disabled="isSendingMessage"
        @click="sendAfterValidation"
        >{{ submitButtonLabel }}</BaseButton
      >
    </BottomActionBar>

    <MemberSelectDialog
      v-if="showMemberSelectDialog"
      :membersSelected="this.studio_message.targets"
      :show="showMemberSelectDialog"
      @close="handleMemberSelectDialogClose"
    />

    <MessageConfirm
      :show="showMessageConfirmModal"
      :count="studio_message.targets.length"
      :usePoint="usePoint"
      :hasPoint="point"
      :smstype="checkByte > 90"
      :isMessage="this.messageType === 'message'"
      :isReserve="studio_message.send_on"
      :isId="$route.params.id"
      @close="handleConfirmMocalClose"
      :disabled="isSendingMessage"
    />

    <el-dialog
      class="over-point-dialog"
      :visible="showTitleOverWriteModal && onceTitleOverWriteModal && isOnModal"
      @close="overWriteClose"
    >
      <h3 slot="title">40바이트 초과</h3>
      40바이트를 초과하였습니다. 40바이트 초과시 문자가 발송 되지 않습니다.
      <div slot="footer">
        <el-button @click="overWriteClose">
          닫기
        </el-button>
      </div>
    </el-dialog>

    <el-dialog class="over-point-dialog" :visible="showOverWriteModal && onceOverWriteModal && isOnModal" @close="overWriteClose">
      <h3 slot="title">2000바이트 초과</h3>
      2000바이트를 초과하였습니다. 2000바이트 초과시 문자가 발송 되지 않습니다.
      <div slot="footer">
        <el-button @click="overWriteClose">
          닫기
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import MemberSelectDialog from '@/components/Modals/MemberSelect';
import MessageConfirm from '@/components/Modals/MessageConfirm';

const DEFALUT_MESSAGE = {
  title: '',
  body: '',
  targets: [],
  member_filter: {},
  send_on: '',
};

export default {
  components: { MemberSelectDialog, MessageConfirm },

  props: {
    hideMemberListButton: { type: Boolean, default: false },
    isOnModal: { type: Boolean, default: false },
    members: { type: Array, default: () => [] },
    defaultMessage: { type: String, default: '' },
    defaultMessageTitle: { type: String, default: '' },
    messageType: { type: String, default: 'sms' },
    placeholder: { type: String, default: '' },
    isStaff: { type: Boolean, default: false },
    staff: { type: Object, default: () => ({}) },
  },

  data() {
    const { moment } = this;

    return {
      /** 메시지 데이터 */
      studio_message: { ...DEFALUT_MESSAGE },
      loadingMessage: false,

      /** Helpers */
      membersSelected: '',
      showSendOnPopover: false,
      sendOn: '',
      sendOnDatePickerOptions: {
        disabledDate(date) {
          return moment(date).format('YYYYMMDD') < moment().format('YYYYMMDD');
        },
      },
      showMemberSelectDialog: false,
      isSendingMessage: false,
      showMessageConfirmModal: false,
      usePoint: 0,
      showTitleOverWriteModal: false,
      showOverWriteModal: false,
      onceTitleOverWriteModal: true,
      onceOverWriteModal: true,
    };
  },

  computed: {
    sendOnDate: {
      get() {
        return this.sendOn ? this.moment(this.sendOn).format('YYYY-MM-DD') : null;
      },
      set(value) {
        const time =
          value === this.moment().format('YYYY-MM-DD')
            ? this.moment()
                .add(15, 'minutes')
                .format('HH:mm')
            : this.sendOnTime || '00:00:00';
        this.sendOn = !value ? null : `${value} ${time}`;
      },
    },

    sendOnTime: {
      get() {
        return this.sendOn ? this.moment(this.sendOn).format('HH:mm') : null;
      },
      set(value) {
        value = value || '00:00';
        this.sendOn = `${this.sendOnDate} ${value}:00`;
      },
    },

    sendOnTimePickerOptions() {
      const { moment, sendOn } = this;
      if (moment(sendOn).format('YYYYMMDD') !== moment().format('YYYYMMDD')) {
        return { start: '00:00', end: '23:55', step: '00:05', minTime: null };
      }
      return {
        start: '00:00',
        end: '23:55',
        step: '00:05',
        minTime: moment()
          .add(10, 'minutes')
          .format('HH:mm'),
      };
    },

    sendOnError() {
      return (
        this.sendOn &&
        this.sendOn <
          this.moment()
            .add(10, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')
      );
    },

    submitButtonLabel() {
      return !this.studio_message.id ? '보내기' : '수정 완료';
    },

    titleCharacterCheck() {
      const regex = /^[a-zA-Z0-9\p{L}\s.,()<>[\]]*$/u;
      return regex.test(this.studio_message.title);
    },

    titleCheckByte() {
      return this.$utils.smsByteCheckWithPreventWriting(this.studio_message.title);
    },

    checkByte() {
      return this.$utils.smsByteCheckWithPreventWriting(this.studio_message.body);
    },

    titleCheck() {
      return this.titleCheckByte > 40;
    },

    lmsCheck() {
      return this.checkByte > 90;
    },

    point() {
      return this.$store.getters['payment/point'];
    },

    studio() {
      return this.$store.getters['studio/studio'];
    },
  },

  watch: {
    $route: {
      handler: 'handleRouteChange',
      immediate: true,
    },
    '$props.members': {
      handler: 'handleMembersChange',
      immediate: true,
    },
    checkByte() {
      if (this.checkByte < 2001 && !this.onceOverWriteModal) {
        this.onceOverWriteModal = true;
        this.showOverWriteModal = false;
      }

      if (this.checkByte > 2000) {
        this.showOverWriteModal = true;
      }
    },
    titleCheckByte() {
      if (this.titleCheckByte < 41 && !this.onceTitleOverWriteModal) {
        this.onceTitleOverWriteModal = true;
        this.showTitleOverWriteModal = false;
      }

      if (this.titleCheckByte > 40) {
        this.showTitleOverWriteModal = true;
      }
    },

    messageType: function() {
      if (this.defaultMessage) {
        this.studio_message.body = this.defaultMessage;
      } else {
        this.studio_message.body = '';
      }
    },
    staff: {
      handler(newVal) {
        if (this.isStaff && newVal) {
          this.studio_message.targets = [{ id: newVal.id }];
          this.membersSelected = newVal.name;
        }
      },
      immediate: true,
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    studio_message: {
      title: { required },
      body: { required },
      targets: { required },
    },
  },

  created() {
    if (this.defaultMessageTitle) {
      this.studio_message.title = this.defaultMessageTitle;
    }

    if (this.defaultMessage) {
      this.studio_message.body = this.defaultMessage;
    }
  },

  methods: {
    async handleRouteChange() {
      const messageId = this._.get(this.$route, 'params.id');

      if (!messageId) {
        this.studio_message = { ...DEFALUT_MESSAGE };
        this.membersSelected = '';
      } else {
        this.loadingMessage = true;
        try {
          if (this.messageType === 'message') {
            const res = await this.$api.message.get(messageId);
            this.studio_message = { ...res.data.studio_message };
            this.studio_message = this._.omit(this.studio_message, 'created_at', 'updated_at', 'deleted_at');
            this.membersSelected = this.studio_message.targets
              .map(({ with_trashed_member: member }) => this._.get(member, 'profile.name'))
              .join(', ');
            const targetsIds = res.data.studio_message.targets.map(({ with_trashed_member: member }) => {
              return { id: member.profile.studio_user_id, name: member.profile.name };
            });
            this.studio_message.targets = targetsIds;
          } else {
            const res = await this.$api.sms.get(messageId);
            const targetsIds = res.data.data.targets.map(({ studio_user_id, name }) => {
              return { id: studio_user_id, name };
            });
            this.studio_message = { ...res.data.data, targets: targetsIds };
            this.membersSelected = this.studio_message.targets.map(({ name }) => name).join(', ');
            this.studio_message.body = res.data.data.message;
          }
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.loadingMessage = false;
        }
      }
    },

    handleMembersChange() {
      const { targets, memberNames } = this.members.reduce(
        ({ targets, memberNames }, member) => {
          targets.push({ id: member.id });
          memberNames.push(member.name);

          return { targets, memberNames };
        },
        { targets: [], memberNames: [] },
      );
      this.studio_message = { ...this.studio_message, targets };
      this.membersSelected = memberNames.length > 1 ? `${memberNames[0]} 외 ${memberNames.length - 1}명` : memberNames.join(', ');
      this.$nextTick(() => {
        this.$refs.title.$el.querySelector('input').focus();
      });
    },

    toggleSendOnPopover() {
      if (!this.showSendOnPopover) this.sendOn = this.studio_message.send_on;
      this.showSendOnPopover = !this.showSendOnPopover;
    },

    setSendOn() {
      if (!this.sendOnError) {
        this.studio_message.send_on = this.sendOn;
        this.showSendOnPopover = false;
      }
    },

    handleMemberSelectDialogClose(result, membersSelected, memberFilterValues) {
      this.showMemberSelectDialog = false;
      this.$nextTick(() => {
        this.$refs.title.$el.querySelector('input').focus();
      });

      if (result && !!membersSelected) {
        this.studio_message.targets = membersSelected.map(({ id, name }) => ({
          id,
          name,
        }));
        this.studio_message.member_filter = { ...memberFilterValues };
        this.membersSelected = membersSelected.map(({ name }) => name).join(', ');
      } else if (result) {
        this.studio_message.targets = [];
        this.studio_message.member_filter = {};
        this.membersSelected = '';
      }
    },

    validateMessage() {
      let status = true;

      if (!this.titleCharacterCheck) {
        this.$utils.notify.error(this, '오류', '제목의 특수문자는 (), <>, []만 사용 가능합니다.');
        status = false;
        return status;
      }

      ['targets', 'title', 'body'].forEach(key => {
        if (!this.$v.studio_message[key].required) {
          this.$v.studio_message[key].$touch();
          status = false;
        }
      });

      if (this.messageType === 'sms' && ['title', 'body'].some(key => this.$utils.emojiCheck(this.studio_message[key]))) {
        this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
        status = false;
      }
      return status;
    },

    sendAfterValidation() {
      if (!this.validateMessage()) return;

      if (this.messageType === 'message') {
        this.handleClickSend();
      } else {
        this.usePoint = this.studio_message.targets.length * (this.lmsCheck ? 37 : 12);
        this.showMessageConfirmModal = true;
      }
    },

    handleConfirmMocalClose(close, accept) {
      this.showMessageConfirmModal = close;
      if (!accept) return;
      this.handleClickSend();
    },

    async handleClickSend() {
      /** 문자 전송시 잔여 포인트 확인 */

      if (this.point < this.usePoint && this.messageType === 'sms') {
        const message = `
          잔여포인트가 부족합니다. <br>
          포인트 충전 후 발송 할 수 있습니다. <br><br>
          잔여 포인트 : ${this.$filters.comma(this.point)}포인트 <br>
          필요 포인트 : ${this.usePoint}포인트
        `;
        const proceed = await this.$confirm(message, '잔여 포인트 부족', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '충전',
        })
          .then(() => true)
          .catch(() => false);
        if (proceed) {
          return this.$router.push('/settings/payments');
        }
        return;
      }

      this.isSendingMessage = true;

      try {
        const { studio_message } = this;
        if (!studio_message.id) {
          let message = '';
          if (this.messageType === 'message') {
            //push 즉시 발송
            const ids = studio_message.targets.map(el => ({ studio_user_id: el.id }));
            studio_message.targets = ids;
            const res = await this.$api.message.create(studio_message);
            message = `${studio_message.targets.length}건의 메시지가 발송되었습니다.`;
            // push 예약 발송
            if (!res.data.studio_message.status) {
              message = `메시지 발송이 예약되었습니다.<br>${this.moment(studio_message.send_on).format(
                'llll',
              )}에 메시지가 발송될 예정입니다.`;
            }
          } else {
            // 문자 즉시 발송
            const params = {
              message: studio_message.body,
              title: studio_message.title,
              users: studio_message.targets.map(el => el.id),
              is_message: false,
              filter: studio_message.member_filter,
              send_on: studio_message.send_on === '' ? null : studio_message.send_on,
            };

            const paramsForStaffSms = {
              message: studio_message.body,
              title: studio_message.title,
              staff_id: studio_message.targets.map(el => el.id)[0],
              is_message: false,
              filter: studio_message.member_filter,
              send_on: studio_message.send_on === '' ? null : studio_message.send_on,
            };

            const res = this.isStaff ? await this.$api.sms.createStaffSms(paramsForStaffSms) : await this.$api.sms.create(params);
            message = `${studio_message.targets.length}건의 메시지가 발송되었습니다.`;

            // 문자 예약 발송
            if (!res.data.is_sent) {
              message = `메시지 발송이 예약되었습니다.<br>${this.moment(studio_message.send_on).format(
                'llll',
              )}에 메시지가 발송될 예정입니다.`;
            }
          }

          this.$utils.notify.success(this, null, message);
          this.resetForm();
        } else {
          // push 예약 수정
          if (this.messageType === 'message') {
            const ids = studio_message.targets.map(({ id }) => ({ studio_user_id: id }));
            await this.$api.message.update({ ...studio_message, targets: ids });
          } else {
            const params = {
              users: studio_message.targets.map(({ id }) => id),
              message: studio_message.body,
              title: studio_message.title,
              is_message: true,
              filter: studio_message.member_filter,
              send_on: studio_message.send_on,
            };

            await this.$api.sms.update(studio_message.id, params);
          }
          this.$utils.notify.success(this, null, '예약 메시지가 수정되었습니다.');
          this.$router.push(`/message/${studio_message.id}`);
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isSendingMessage = false;
        this.$emit('messageSent');
        this.$store.dispatch('payment/getPointScore');
      }
    },

    resetForm() {
      this.studio_message = { ...DEFALUT_MESSAGE };
      this.membersSelected = '';
      this.$v.$reset();
    },

    overWriteClose() {
      this.showTitleOverWriteModal = false;
      this.onceTitleOverWriteModal = false;
      this.onceOverWriteModal = false;
      this.showOverWriteModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  &__point-info {
    display: flex;
    position: relative;
    background-color: rgba(100, 174, 255, 0.1);
    height: 40px;
    border-radius: 4px;
    line-height: 40px;
    border: 1px solid $deep-sky-blue;
    &__infoPoint {
      display: flex;
      width: 50%;
      justify-content: center;
      p:nth-child(2) {
        margin: 0 24px;
      }
    }
    div:nth-child(2) {
      font-size: 15px;
      color: $deep-sky-blue;
      font-weight: bold;
    }
    div:nth-child(3) {
      color: $deep-sky-blue;
      font-weight: bold;
      width: 500px;
      text-align: center;
      button {
        margin: 6px 30px;
        height: 25px;
        line-height: 15px;
      }
    }
  }

  &__input {
    @include flex(row);

    &.has-button {
      @include flex(column, flex-end, center);

      @include mq(568px) {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: stretch;
        grid-gap: 16px;
      }
    }
  }

  &__helpers {
    @include flex(row, center, space-between);

    &__send-on {
      font-size: 14px;
      font-weight: 500;
      margin-right: 16px;
    }

    &__over-2000byte {
      color: red;
      font-weight: bold;
    }
  }

  &__send-on {
    min-width: 320px;

    &__header,
    &__body,
    &__footer {
      padding: 24px;
    }

    &__header {
      @include flex(row, center, space-between);

      h4 {
        font-size: 18px;
        font-weight: 500;
      }
    }

    &__body {
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-template-rows: auto auto 16px;
      grid-gap: 6px;
      align-items: center;

      p {
        grid-column: span 2;
        margin-bottom: 12px;
      }

      /deep/ .el-date-editor {
        min-width: 140px;
      }

      span {
        grid-column: 2 / 2;
        color: $color-danger;
        font-size: 12px;
      }
    }

    &__footer {
      display: grid;
      grid-template-columns: repeat(2, 75px);
      grid-gap: 10px;
      justify-content: end;
      border-top: 1px solid #f0f0f0;
    }
  }

  &__modal-buttons {
    @include flex(row, center, space-between);
    margin-top: 30px;
  }
}

.over-point-dialog {
  /deep/ .el-dialog {
    width: 530px !important;
    margin-top: 35vh !important;
    &__header {
      padding: 20px 30px !important;
    }
    &__body {
      display: block !important;
      padding: 0px 30px 0 30px !important;
    }
  }
}
</style>
