<template>
  <section class="permissions" v-loading="isLoading">
    <h6>접근을 허용할 기능들을 체크해주세요</h6>
    <div class="permissions__controls">
      <custom-tabs
        class="permissions__controls__tabs"
        active_tab_color="#4a90e2"
        :active_tab="activePermissionTab"
        :tabs="permissionTabs"
        :handleChangeTab="handleChangeTab"
      />
      <div v-if="!isRoleImmutable" class="permissions__controls__buttons">
        <el-button :type="isCheckedAll ? 'default' : 'primary'" size="small" round @click="toggleCheckAll">{{
          isCheckedAll ? '모두 해제' : '모두 선택'
        }}</el-button>
        <el-button type="warning" size="small" round @click="savePermissions">저장</el-button>
      </div>
      <p v-else class="permissions__controls__owner-message">
        스튜디오 오너는 모든 권한을 가집니다.
      </p>
    </div>

    <ul class="permissions__list" ref="permissions__list">
      <li class="permissions__list__title">
        <span>{{ activeTab.description }}</span>
      </li>
      <li v-for="item in permissionItems" :key="item.id">
        <permissions-list-item :data="generateData(item)" />
      </li>
    </ul>
  </section>
</template>

<script>
import PermissionsListItem from '@/components/Settings/PermissionsListItem';

export default {
  components: { PermissionsListItem },

  data() {
    return {
      checkedPermissionIds: [],
      expandChildrenIds: [],
      isLoading: false,
    };
  },

  computed: {
    activeRole() {
      return this.$store.getters['studio/activeRole'];
    },
    permissions() {
      return this.$store.getters['studio/permissions'];
    },
    permissionItems() {
      return this.$store.getters['studio/permissionItems'];
    },
    permissionTabs() {
      return this.$store.getters['studio/permissionTabs'];
    },
    activePermissionTab() {
      return this.$store.getters['studio/activePermissionTab'];
    },
    /** 현재 탭의 제목행 */
    activeTab() {
      const { permissionTabs, activePermissionTab } = this;
      const activeTab = permissionTabs.find(({ value }) => value === activePermissionTab);

      if (!activeTab) return { label: '', description: '' };

      return {
        label: activeTab.label,
        description: `${activeTab.label}에 관한 접근 권한입니다.`,
      };
    },

    /** 현재 탭의 모든 권한 */
    activeTabPermissions() {
      let all = [];
      let parents = [];
      const activeTabPermission = this.permissions.find(({ name }) => name === this.activePermissionTab);

      if (activeTabPermission) {
        activeTabPermission.items.forEach(item => {
          all.push(item.id);
          if (item.children.length) {
            parents.push(item.id);
            item.children.forEach(child => {
              all.push(child.id);
            });
          }
        });
      }

      return { all, parents };
    },

    isRoleImmutable() {
      if (!this.activeRole) return false;
      return this.activeRole.is_immutable;
    },

    isCheckedAll() {
      return this.activeTabPermissions.all.reduce((result, id) => {
        return result && this.checkedPermissionIds.includes(id);
      }, true);
    },
  },

  watch: {
    activeRole: 'resetCheckedPermission',
    activePermissionTab: 'resetExpandChildren',
    $route: {
      handler: 'resetExpandChildren',
      immediate: true,
    },
  },

  created() {
    this.$store.dispatch('studio/getRoles', this.activeRole?.id);
  },

  methods: {
    handleChangeTab(tab) {
      this.$store.commit('studio/SET_ACTIVE_PERMISSION_TAB', tab);
    },

    /** 권한 수정사항 초기화 */
    resetCheckedPermission() {
      this.checkedPermissionIds = this.activeRole.permissions.map(({ id }) => id);
      this.resetExpandChildren();
    },

    /** 하위 권한 펼쳐짐 여부 초기화 */
    resetExpandChildren() {
      this.expandChildrenIds = this.activeTabPermissions.parents.filter(id => {
        return this.checkedPermissionIds.includes(id);
      });
    },

    /** 권한 리스트 표시를 위한 데이터 가공 */
    generateData(permission) {
      const { isRoleImmutable, checkedPermissionIds, handleChangeCheckbox, generateData, toggleExpandChildren } = this;
      const checked = isRoleImmutable || checkedPermissionIds.includes(permission.id);
      const children = permission.children.map(child => generateData(child));

      /**
       * 체크박스 비활성화
       * 1. 오너 계정일 경우
       * 2. 상위 권한이 체크 해제일 경우
       */
      let disabled = false;
      if (isRoleImmutable) {
        disabled = true;
      } else if (permission.parent_id) {
        disabled = !checkedPermissionIds.includes(permission.parent_id);
      }

      let show_children = false;
      if (permission.children.length) {
        show_children = this.expandChildrenIds.includes(permission.id);
      }

      return {
        value: permission.id,
        label: permission.display_name,
        description: permission.description,
        checked,
        disabled,
        handleChange: handleChangeCheckbox,
        children,
        show_children,
        toggleExpandChildren,
      };
    },

    /** 체크박스 상태 변경시 */
    handleChangeCheckbox(permissionId) {
      const permissionIndex = this.checkedPermissionIds.findIndex(id => id === permissionId);
      const expandChildrenId = this.expandChildrenIds.findIndex(id => id === permissionId);

      /**
       * 체크 / 체크 해제
       * 하위 권한 펼침 / 숨김
       */
      if (permissionIndex < 0) {
        this.checkedPermissionIds.push(permissionId);
        if (expandChildrenId < 0) {
          this.expandChildrenIds.push(permissionId);
        }
      } else {
        this.checkedPermissionIds = this.checkedPermissionIds.filter(id => id !== permissionId);
        if (expandChildrenId > -1) {
          this.expandChildrenIds = this.expandChildrenIds.filter(id => id !== permissionId);
        }
      }

      /** 하위 권한 있을 경우 */
      const permission = this.permissionItems.find(permission => {
        return permission.id === permissionId && !!permission.children.length;
      });
      if (permission) {
        permission.children.forEach(childPermission => {
          /** 체크 해제시: 하위 권한 모두 체크 해제 */
          if (permissionIndex > -1) {
            this.checkedPermissionIds = this.checkedPermissionIds.filter(id => id !== childPermission.id);
            /** 체크시: 하위 권한 모두 체크 */
          } else if (!this.checkedPermissionIds.includes(childPermission.id)) {
            this.checkedPermissionIds.push(childPermission.id);
          }
        });
      }
    },

    /** 하위 권한 펼침/숨김 */
    toggleExpandChildren(permissionId) {
      const index = this.expandChildrenIds.findIndex(id => id === permissionId);
      if (index < 0) {
        this.expandChildrenIds.push(permissionId);
      } else {
        this.expandChildrenIds.splice(index, 1);
      }
    },

    /** 전체 선택 / 해제 */
    toggleCheckAll() {
      if (this.isCheckedAll) {
        this.checkedPermissionIds = this.checkedPermissionIds.filter(id => !this.activeTabPermissions.all.includes(id));
        this.expandChildrenIds = [];
      } else {
        this.checkedPermissionIds = [...this.checkedPermissionIds, ...this.activeTabPermissions.all];
        this.expandChildrenIds = [...this.activeTabPermissions.parents];
      }
    },

    /** 권한 변경사항 저장 */
    async savePermissions() {
      try {
        this.isLoading = true;
        const { id, display_name } = this.activeRole;
        const permission_ids = this.checkedPermissionIds.toString();

        await this.$api.studio.roles.permissions.update(id, permission_ids);
        this.$utils.notify.success(this, '확인', `<b>${display_name}</b>의 권한을 저장했습니다.`);
        this.$store.dispatch('studio/getRoles', id);
        // 본인의 역할별 권한 설정이 바뀜에 따라 라우터에서 튕겨야 할 경우도 있음으로 currentUser 최신화를 위한 dispatch
        // TODO 역할별 권한설정만 최신화해도 되면 분기처리 필요.
        this.$store.dispatch('auth/getCurrentUser', false);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions {
  flex: 1;
  min-width: 420px;

  h6 {
    opacity: 0.8;
    font-size: 12px;
    margin-bottom: 20px;
  }

  &__controls {
    @include flex(row, center, space-between);
    border-bottom: 1px solid #ccc;

    &__owner-message {
      color: $color-danger;
      font-weight: bold;
    }
  }

  &__list {
    @include flex(column, center, flex-start);
    max-height: calc(100vh - 410px);
    overflow: auto;

    li {
      width: 100%;
    }

    &__title {
      font-size: 11px;
      opacity: 0.6;
      padding: 20px 0;
    }
  }
}
</style>
