<template>
  <div class="permissions-list-item">
    <div class="permissions-list-item__inner">
      <h5 v-if="has_subtitle.includes(data.value)">
        {{ subtitle[data.value] }}
      </h5>
      <div class="permissions-list-item__checkbox">
        <custom-checkbox
          color="#4a90e2"
          :value="data.value"
          :checked="data.checked"
          :disabled="data.disabled"
          @change="data.handleChange"
        >
          <span class="permissions-list-item__checkbox__label">
            {{ data.label }}
          </span>
        </custom-checkbox>
        <p :class="{ show_children: data.show_children }" v-html="descriptionWithBr(data.description)" />
      </div>

      <button
        v-if="data.children.length !== 0"
        class="permissions-list-item__toggle-button"
        :class="{ open: data.show_children }"
        @click="data.toggleExpandChildren(data.value)"
      >
        <img
          src="@/assets/images/icons/down.png"
          srcset="@/assets/images/icons/down@2x.png 2x, @/assets/images/icons/down@3x.png 3x"
        />
      </button>
    </div>

    <div ref="permissions-list-item__children" class="permissions-list-item__children" :class="{ hide: !data.show_children }">
      <div v-for="child in data.children" :key="child.value" class="permissions-list-item__inner child">
        <div class="permissions-list-item__line">
          <div class="permissions-list-item__line__top" />
          <div class="permissions-list-item__line__bottom" />
        </div>
        <div class="permissions-list-item__checkbox">
          <custom-checkbox
            color="#4a90e2"
            :value="child.value"
            :checked="child.checked"
            :disabled="child.disabled"
            @change="child.handleChange"
          >
            <span class="permissions-list-item__checkbox__label">
              {{ child.label }}
            </span>
          </custom-checkbox>
          <p v-html="descriptionWithBr(child.description)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { data: Object },
  data() {
    return {
      has_subtitle: [101207, 101211, 101216, 101221, 101252],
      subtitle: {
        101207: '본인의 그룹 수업',
        101211: '본인의 프라이빗 수업',
        101216: '다른 스태프의 그룹 수업',
        101221: '다른 스태프의 프라이빗 수업',
        101252: '일정 메모',
      },
    };
  },
  computed: {
    descriptionWithBr() {
      return description => {
        if (!description) return '';
        return description.replace(/\\n/g, '<br>');
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions-list-item {
  @include flex(column, stretch, center);

  &__inner {
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    padding-right: 24px;

    h5 {
      width: 100%;
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 23px;
    }

    &.child {
      margin-left: 15px;
      padding-left: 0;
    }
  }

  &__inner.child &__line {
    align-self: stretch;
    display: grid;
    grid-template-rows: 16px auto;
    width: 47px;
  }

  &__inner.child &__line__top {
    border: solid #ccc;
    border-width: 0 0 1px 1px;
  }

  &__inner.child &__line__bottom {
    border-left: 1px solid #ccc;
  }

  &__inner.child:last-child &__line__bottom {
    border: none;
  }

  &__checkbox {
    flex: 1;

    &__label {
      font-size: 16px;
      font-weight: 300;
    }

    p {
      border-left: 1px solid transparent;
      opacity: 0.8;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.2px;
      margin-left: 15px;
      padding: 5px 0 36px 21px;

      &.show_children {
        border-color: #ccc;
      }
    }
  }

  &__toggle-button {
    @include flex(row, center, center);
    border-radius: 50%;
    margin-left: 18px;
    padding: 6px;
    transition: 0.15s;

    img {
      transition: 0.15s;
    }

    &:hover {
      background: $gray-200;
    }

    &.open {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__children {
    display: block;

    &.hide {
      display: none;
    }
  }
}
</style>
