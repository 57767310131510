<template>
  <div v-loading="loading" class="wrap-bg" :style="fullscreen && 'height: 100vh !important;'">
    <section class="content-title">
      <div class="content-title__time">
        <i class="el-icon-time" />
        <div>{{ nowTime }}</div>
      </div>
      <div v-if="currentStep !== 1" class="content-title__bector-wrap">
        <button class="bector-icon" />
        <button class="bector-icon" :disabled="currentStep === 2" />
        <button class="bector-icon" :disabled="currentStep === 2 || currentStep < 4" />
      </div>
      <div class="content-title__confirm-icon">
        <img v-if="currentStep === 1" src="@/assets/images/icons/confirm_lecture.svg" alt="confirm_checkin" />
        <img
          v-else-if="currentStep === 4 && !checkinFailed"
          src="@/assets/images/icons/confirm_checkin.svg"
          alt="confirm_lecture"
        />
        <img
          v-else-if="currentStep === 4 && checkinFailed"
          src="@/assets/images/icons/failed_checkin.svg"
          alt="confirm_lecture"
        />
      </div>
      <div v-html="stepTitle" class="content-title__main-text"></div>
      <RoundButton v-if="currentStep <= 3" class="btn-back" @click="currentBack(currentStep)">
        이전 단계로
      </RoundButton>
    </section>

    <!-- Step 1 중복 회원 있을 시 리스트 -->
    <article :style="currentStep === 4 && 'display: unset'">
      <div class="btn-wrap">
        <RoundButton
          v-show="
            (currentStep === 1 && dataSet.multiMember.length > 3) ||
              (currentStep === 2 && ticketLists.length > 3) ||
              (currentStep === 3 && currentLectureList.length > 2)
          "
          class="btn-list-page"
          :disabled="
            (currentStep === 1 && multiPrevIdx === 0) ||
              (currentStep === 2 && ticketPrevIdx === 0) ||
              (currentStep === 3 && lecturePrevIdx === 0)
          "
          @click="movePage('prev')"
        >
          <img src="@/assets/images/icons/icon_arrow-top.svg" alt="arrow-top" />
          <span>이전 페이지</span>
        </RoundButton>
      </div>

      <section v-if="currentStep === 1" class="list-template">
        <ul>
          <li
            v-for="member in multiMembers"
            :key="member.id"
            :style="multiMembers.length >= 3 && 'flex-grow: 1;'"
            @click="getTicketList(member)"
          >
            <div class="list-template__wrap" style="flex-direction: row; justify-content: flex-start; align-items: center;">
              <div class="list-template__profile" style="display: flex; align-items: center; margin-right: 10px">
                <img :src="$utils.getImagePath(member.member_avatar, 0)" style="margin: 0" alt="profile" />
              </div>
              <div class="list-template__title">
                <div>
                  <h3 v-html="member.name" style="display: flex; align-items: center" />
                  <p>회원님</p>
                </div>
                <p v-html="setMobile(member.mobile)" class="title-middle" />
              </div>
              <img class="list-template__watermark" src="@/assets/images/04_watermark/watermark_symbol.svg" alt="watermark" />
            </div>
          </li>
        </ul>
      </section>

      <!-- Step 2 수강권 리스트 -->
      <section v-if="currentStep === 2" class="list-template">
        <ul>
          <li
            :style="memberTickets.length >= 3 && 'flex-grow: 1;'"
            v-for="ticket in memberTickets"
            :key="ticket.id"
            @click="getLectureList(ticket)"
          >
            <div class="list-template__wrap" :style="ticket.lecture_type === '그룹형' ? groupStyle : privateStyle">
              <div class="list-template__title">
                <div>
                  <h3 v-html="ticket.title" />
                </div>
              </div>
              <p class="list-template__types">
                {{ ticket.ticket_type }} / {{ ticket.lecture_type }} / {{ ticket.trainee }}
                <span v-if="!!ticket.booking_limit">/ {{ ticket.booking_limit }}</span>
              </p>
              <div class="list-template__info">
                <div class="info-date">
                  <p>사용기한</p>
                  <p v-html="usePeriodSet(ticket)" />
                </div>
                <div v-if="ticket.ticket_type === '횟수제'" class="info-count">
                  <p>
                    예약가능 <b>{{ ticket.usable_coupon }}</b>
                  </p>
                  <span>|</span>
                  <p>
                    취소가능 <b>{{ ticket.usable_cancel }}</b>
                  </p>
                  <span>|</span>
                  <p>
                    잔여횟수 <b>{{ ticket.remaining_coupon }}</b>
                  </p>
                </div>
              </div>
              <div class="list-template__watermark" style="bottom: 10px">
                <img
                  src="@/assets/images/04_watermark/watermark_symbol.svg"
                  alt="watermark-symbol"
                  style="margin-top: 4px; width: 46px;"
                />
                <div v-html="ticket.lecture_type === '그룹형' ? 'Group' : 'Private'" />
              </div>
            </div>
          </li>
        </ul>
      </section>

      <!-- Step 3 수업 리스트 -->
      <section v-if="currentStep === 3" class="list-template">
        <ul>
          <li v-for="(lecture, idx) in currentLectures" :key="idx" :style="currentLectures.length !== 1 && 'flex-grow: 1;'">
            <div class="list-template__wrap" @click="showConfirmModal(lecture)" style="padding-left: 28px;">
              <div class="list-template__title" style="display: flex;">
                <div class="list-template__profile" style="margin-right: 20px;">
                  <img
                    :src="$utils.getImagePath(lecture.staff_avatar, 0)"
                    alt="profile"
                    :style="checkRowHeight() ? lectureRowStyle.profile : 'margin: 0; width: 80px; height: 80px;'"
                  />
                </div>
                <div style="display: flex; flex-direction: column; justify-content: center;">
                  <div class="lecture-date-wrap">
                    <h3
                      class="lecture-time"
                      v-html="checkinTime(lecture)"
                      :style="checkRowHeight() ? lectureRowStyle.time : ''"
                    />
                    <img v-if="lecture.booking_id" src="@/assets/images/icons/icon_booking_tag.svg" alt="icon-tag" />
                  </div>
                  <p class="lecture-date" v-html="checkinDate(lecture)" style="margin: 0; line-height: 1.4;" />
                </div>
              </div>
              <div class="list-template__title" :style="checkRowHeight() ? lectureRowStyle.title : 'margin-top: 14px;'">
                <h3 v-html="lecture.title" />
                <div class="lecture-staff">
                  <p>{{ lecture.staff_name }}</p>
                  <span>강사</span>
                </div>
              </div>
              <div class="list-template__info-time">
                <span>체크인 가능 시간</span>
                <p v-html="checkinPossibleTime(lecture)" />
              </div>
              <img
                class="list-template__watermark"
                src="@/assets/images/04_watermark/watermark_symbol.svg"
                alt="watermark-symbol"
              />
            </div>
          </li>
        </ul>
      </section>

      <!-- Step 4 체크인 완료 -->
      <section v-if="currentStep === 4" class="success-checkin">
        <div class="success-checkin__header">
          <img src="@/assets/images/02_icons/icon_ticket.svg" alt="icon-ticket" />
          <h2>수강권을 확인해 주세요</h2>
        </div>
        <div>
          <div class="success-checkin__content" style="display: flex; flex-direction: row; gap: 15px;">
            <div class="content-title">회원명</div>
            <div class="content-body">{{ memberName }}</div>
          </div>
          <div class="success-checkin__content" style="display: flex; flex-direction: row; gap: 15px;">
            <div class="content-title">수업명</div>
            <div class="content-body">{{ finalLecture.title }}</div>
          </div>
          <div class="success-checkin__content">
            <div class="content-title">이용기간</div>
            <div class="content-body">{{ lectureSchedule }}</div>
          </div>
          <div class="success-checkin__content remaining">
            <div>
              <div class="content-title">잔여일</div>
              <div class="content-body">{{ saveTicket.remaining_day }}일</div>
            </div>
            <div v-if="saveTicket.ticket_type === '횟수제'">
              <div class="content-title">잔여횟수</div>
              <div class="content-body">
                {{ !checkinFailed ? saveTicket.remaining_coupon - 1 : saveTicket.remaining_coupon }}회
              </div>
            </div>
          </div>
        </div>
        <h4 class="success-checkin__hide-text">이 창은 5초 후에 자동으로 사라져요</h4>
        <div class="success-checkin__bottom">
          <RoundButton class="asd" :style="`${styledButton}height: 60px;`" @click="goHome">처음으로</RoundButton>
        </div>
      </section>

      <div class="btn-wrap">
        <RoundButton
          v-show="
            (currentStep === 1 && dataSet.multiMember.length > 3) ||
              (currentStep === 2 && ticketLists.length > 3) ||
              (currentStep === 3 && currentLectureList.length > 2)
          "
          class="btn-list-page"
          :disabled="nextBtnDisabled"
          @click="movePage('next')"
        >
          <img src="@/assets/images/icons/icon_arrow-bottom.svg" alt="arrow-bottom" />
          <span>다음 페이지</span>
        </RoundButton>
      </div>
    </article>

    <WarningCheckin :show="showWarningModal" :title="modalData.title" :content="modalData.content" :onClose="onClose" />
    <InfoCheckin
      :show="showModal"
      :title="modalData.title"
      :lectureInfo="modalData.lectureInfo"
      :confirmCheckin="confirmCheckin"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import WarningCheckin from '@/components/Modals/WarningCheckin';
import InfoCheckin from '@/components/Modals/InfoCheckin';

export default {
  components: {
    WarningCheckin,
    InfoCheckin,
  },

  props: {
    show: Boolean,
    dataSet: Object,
    fullscreen: Boolean,
  },

  data() {
    return {
      nowTime: String,
      currentStep: this.dataSet.screenStep,
      showWarningModal: false,
      showModal: false,
      memberName: String,
      memberId: Number,
      stepTitle: String,
      userTicketId: Number,
      finalLecture: Object,
      saveMember: Object,
      saveTicket: Object,
      memberTickets: Array,
      multiMembers: Array,
      currentLectures: Array,
      multiPrevIdx: 0,
      multiNextIdx: 3,
      ticketPrevIdx: 0,
      ticketNextIdx: 3,
      lecturePrevIdx: 0,
      lectureNextIdx: 2,
      nextBtnDisabled: false,
      loading: false,
      checkinFailed: false,
      groupStyle: 'border-left: 15px solid #8592f3',
      privateStyle: 'border-left: 15px solid #f385ab;',
      styledButton: `
        visibility: hidden;
        margin-top: 15px;
        width: 100%;
        height: 70px;
        border-radius: 16px;
        background-color: #4a8eff;
        transform: translate(0, -100px);
        transition: .5s;
      `,
      style: {
        outline: '2px solid #4a8eff',
        shadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      },

      modalData: {
        title: '',
        content: '',
        lectureInfo: [],
      },

      lectureRowStyle: {
        profile: 'margin: 0; width: 60px; height: 60px;',
        time: 'font-size: 20px;',
        title: 'margin-top: 14px; display: flex; gap: 10px; align-items: flex-end;',
      },
    };
  },

  created() {
    this.nowTimes();
    setInterval(() => this.nowTimes(), 1000);

    this.multiMembers = this.dataSet.multiMember.slice(0, 3);
  },

  computed: {
    ticketLists() {
      return this.$store.getters['checkin/tickets'];
    },

    lectureLists() {
      return this.$store.getters['checkin/lectures'];
    },

    lectureSchedule() {
      const startOn = this.moment(this.saveTicket.start_at).format('YYYY년 MM월 DD일');
      const endOn = this.moment(this.saveTicket.expire_at).format('YYYY년 MM월 DD일');

      return `${startOn} ~ ${endOn}`;
    },

    currentLectureList() {
      const bookings = this.lectureLists.bookings;
      const lectures = this.lectureLists.lectures;

      if (!bookings.length) return lectures;
      else if (bookings.length && lectures.length) return [...bookings, ...lectures];
      else return bookings;
    },
  },

  beforeMount() {
    if (this.currentStep === 1) {
      /** 중복 번호일 경우*/
      this.stepTitle = '중복된 번호에요.<br>회원님을 선택해 주세요!';
    } else if (this.currentStep === 2 && !this.dataSet.byQR) {
      /** 수강권 리스트 */
      this.getTicketList(this.dataSet.multiMember[0]);
      this.stepTitle = '체크인을 진행할<br>수강권을 선택해 주세요';
    }
  },

  methods: {
    checkRowHeight() {
      return window.innerHeight < 680 ? true : false;
    },

    nowTimes() {
      this.nowTime = this.moment().format('YYYY년 MM월 DD일 HH : mm : ss');
    },

    async getTicketList(member) {
      this.saveMember = member;
      this.memberName = member.name;
      this.memberId = member.member_id;

      const result = await this.$store.dispatch('checkin/getTickets', this.memberId);

      if (result.success && this.ticketLists.length < 4) this.memberTickets = this.ticketLists;
      else if (result.success && this.ticketLists.length > 3) this.memberTickets = this.ticketLists.slice(0, 3);

      this.pageIdxInit();
      this.currentStep = 2;
      this.stepTitle = '체크인을 진행할<br>수강권을 선택해 주세요';
    },

    //step3으로 넘어가는(수업 리스트를 호출하는) 함수
    async getLectureList(selectedTicket) {
      this.saveTicket = selectedTicket;

      const res = await this.$store.dispatch('checkin/getLectures', {
        member_id: this.memberId,
        user_ticket_id: selectedTicket.user_ticket_id,
      });

      if (res.success) {
        this.currentLectures = this.currentLectureList.slice(0, 2);
        if (!this.lectureLists.bookings.length && !this.lectureLists.lectures.length) return this.notDataModal('lecture');

        // this.makeLectureLists(this.lectureLists);
        this.userTicketId = selectedTicket.user_ticket_id;
        this.currentStep = 3;
        this.stepTitle = '체크인을 진행할<br>수업을 선택해 주세요';
      }
    },

    /** 기존에 있던 필수 예약 관련된 코드, 백엔드측과 상의 결과 혹시 몰라 남겨놓기로 함 */
    // makeLectureLists(item) {
    //   if (item.bookings) {
    //     const updateItem = item.bookings;
    //     updateItem.map(item => {
    //       //lectures와 같은 포맷으로 데이터 업데이트
    //       item.title = item.lecture.title;
    //       item.staffName = item.lecture.staff.name;
    //       item.avatars = item.lecture.staff.avatars;
    //       item.start_on = item.lecture.start_on;
    //       item.end_on = item.lecture.end_on;
    //       item.is_booking_only = item.lecture.is_booking_only;
    //       item.booking_mark = true;
    //     });
    //     this.contentList = updateItem; //예약된 수업
    //   }
    //   if (item.lectures) {
    //     //예약 안한 수업
    //     const list = item.lectures;
    //     list.map(item => {
    //       item.staffName = item.staff.name;
    //       item.avatars = item.staff.avatars;
    //     });
    //     if (list) {
    //       this.contentList = this.contentList.concat(list);
    //     }
    //   }
    // },

    onClose() {
      this.showModal = false;
      this.showWarningModal = false;
    },

    goHome() {
      this.$emit('showIntro', true);
    },

    setMobile(mobile) {
      const firstNum = mobile.slice(0, 3);
      const middleNum = mobile.length === 10 ? mobile.slice(3, 6) : mobile.slice(3, 7);
      const finalNum = mobile.length === 10 ? mobile.slice(6, 10) : mobile.slice(7, 11);

      return `${firstNum}-${middleNum}-${finalNum}`;
    },

    notDataModal(type) {
      this.modalData.title = `체크인을 사용할 수 있는<br>${type === 'lecture' ? '수업' : '수강권'}이 없어요!`;
      this.modalData.content = `시설에 ${type === 'lecture' ? '수업' : '수강권'}을 문의해 주세요!`;
      this.showWarningModal = true;
    },

    confirmCheckin(lecture) {
      this.loading = true;
      this.finalLecture = lecture;
      this.showModal = false;
      this.currentStep = 4;
      this.styledButton = `
        visibility: visible;
        margin-top: 15px;
        width: 100%;
        height: 70px;
        border-radius: 16px;
        background-color: #4a8eff;
      `;

      setTimeout(() => this.goHome(), 5000);
      this.checkinLecture(lecture);
    },

    async checkinLecture(lecture) {
      let params = {};
      this.stepTitle = '수업이<br>체크인되었어요!';

      const lectureId = this.lectureLists.lectures.length ? this.finalLecture.lecture_id : this.finalLecture.booking_id;

      if (lecture.type === 'booking') {
        params = {
          member_id: this.memberId,
          booking_id: this.finalLecture.booking_id,
        };
      } else {
        params = {
          member_id: this.memberId,
          booking_id: this.finalLecture.booking_id,
          lecture_id: lectureId,
          user_ticket_id: this.userTicketId,
        };
      }

      const res = await this.$store.dispatch('checkin/checkinLecture', params);

      if (res.success) {
        this.checkinFailed = false;
        this.resultSuccess();
      } else {
        const errorCode = res.error.response.data.code;
        const errorMessage = res.error.response.data.message;
        const dateUnit = errorCode > 10605 ? '당월' : errorCode === 10000 ? '이번 주' : '하루에';

        this.loading = false;
        this.stepTitle = '체크인에 실패했어요!';
        this.checkinFailed = true;
        this.modalData.title =
          errorCode === 10016 || errorCode === 10006 ? errorMessage : `${dateUnit} 예약할 수 있는<br>최대 횟수를 초과했어요.`;
        this.modalData.content = '다시 한 번 시도해 주세요!';
        this.showWarningModal = true;
      }
    },

    resultSuccess() {
      new Audio('https://check-in-audio.s3.ap-northeast-2.amazonaws.com/household_door_bell_ding_dong_single.mp3').play();
      this.loading = false;
    },

    currentBack(step) {
      if (step === 2 && this.dataSet.multiMember.length > 1) {
        this.currentStep = 1;
        this.stepTitle = '중복된 번호에요.<br>회원님을 선택해 주세요!';
      } else if (step === 1 || step === 2) this.goHome();
      else if (step === 3) this.getTicketList(this.saveMember);
      else this.getLectureList(this.saveTicket);
    },

    checkinTime(lecture) {
      const startOn = this.moment(lecture.start_on).format('HH : mm');
      const endOn = this.moment(lecture.end_on).format('HH : mm');
      return `${startOn} ~ ${endOn}`;
    },

    checkinDate(lecture) {
      return this.moment(lecture.start_on).format('YYYY. MM. DD (dd)');
    },

    checkinPossibleTime(lecture) {
      const startAt = lecture.enter_start_at;
      const endAt = lecture.enter_end_at;

      const startYear = startAt.slice(0, 5);
      const endYear = endAt.slice(0, 5);
      const currentEndAt = endAt.slice(5, 13);

      if (startYear !== endYear) return `${startAt} ~ ${endAt}`;

      return `${startAt} ~ ${currentEndAt}`;
    },

    usePeriodSet(ticket) {
      const standardDate = this.moment().subtract(1, 'days');
      const expireAt = this.moment(ticket.expire_at);
      const remainingPeirod = Math.abs(standardDate.diff(expireAt, 'days'));

      if (remainingPeirod === 0) return `<b>${ticket.start_at} ~ ${ticket.expire_at} (오늘 만료)</b>`;
      else return `<b>${ticket.start_at} ~ ${ticket.expire_at} (${remainingPeirod}일 남음)</b>`;
    },

    showConfirmModal(lecture) {
      this.modalData.title = '이 수업을<br>체크인할까요?';
      this.modalData.lectureInfo = [this.checkinTime(lecture), lecture.title, lecture];
      this.showModal = true;
    },

    movePage(type) {
      //** Step 1 중복회원 */
      if (this.currentStep === 1) {
        if (type === 'next') {
          this.multiPrevIdx = this.multiPrevIdx + 3;
          this.multiNextIdx = this.multiNextIdx + 3;
        } else {
          this.multiPrevIdx = this.multiPrevIdx - 3;
          this.multiNextIdx = this.multiNextIdx - 3;
        }

        this.multiMembers = this.dataSet.multiMember.slice(this.multiPrevIdx, this.multiNextIdx);

        const lastIdx = this.dataSet.multiMember[this.dataSet.multiMember.length - 1].member_id;
        const currentLastIdx = this.multiMembers[this.multiMembers.length - 1].member_id;

        if (currentLastIdx === lastIdx) this.nextBtnDisabled = true;
        else this.nextBtnDisabled = false;
      }

      //** Step 2 수강권 리스트 */
      else if (this.currentStep === 2) {
        if (type === 'next') {
          this.ticketPrevIdx = this.ticketPrevIdx + 3;
          this.ticketNextIdx = this.ticketNextIdx + 3;
        } else {
          this.ticketPrevIdx = this.ticketPrevIdx - 3;
          this.ticketNextIdx = this.ticketNextIdx - 3;
        }

        this.memberTickets = this.ticketLists.slice(this.ticketPrevIdx, this.ticketNextIdx);

        const lastIdx = this.ticketLists[this.ticketLists.length - 1].user_ticket_id;
        const currentLastIdx = this.memberTickets[this.memberTickets.length - 1].user_ticket_id;

        if (currentLastIdx === lastIdx) this.nextBtnDisabled = true;
        else this.nextBtnDisabled = false;
      }

      //** Step 3 수업 리스트 */
      else if (this.currentStep === 3) {
        if (type === 'next') {
          this.lecturePrevIdx = this.lecturePrevIdx + 2;
          this.lectureNextIdx = this.lectureNextIdx + 2;
        } else {
          this.lecturePrevIdx = this.lecturePrevIdx - 2;
          this.lectureNextIdx = this.lectureNextIdx - 2;
        }

        this.currentLectures = this.currentLectureList.slice(this.lecturePrevIdx, this.lectureNextIdx);

        const lastIdx = this.currentLectureList[this.currentLectureList.length - 1].id;
        const currentLastIdx = this.currentLectures[this.currentLectures.length - 1].id;

        if (currentLastIdx === lastIdx) this.nextBtnDisabled = true;
        else this.nextBtnDisabled = false;
      }
    },

    pageIdxInit() {
      this.multiPrevIdx = 0;
      this.multiNextIdx = 3;
      this.ticketPrevIdx = 0;
      this.ticketNextIdx = 3;
      this.lecturePrevIdx = 0;
      this.lectureNextIdx = 2;
    },
  },

  watch: {
    currentStep() {
      this.nextBtnDisabled = false;
    },
  },
};
</script>

<style lang="scss" scope>
.wrap-bg {
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  touch-action: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;

  @include flex(row, stretch, center);
  gap: 120px;
  padding: 3%;
  height: 100vh;
  background-color: #f4f4f4;
  overflow: auto;
  font-family: $spoqa-han-sans;

  @include mq-max(1200px) {
    gap: 60px;
  }

  article {
    @include flex(column, center, space-between);

    .btn-wrap {
      margin-bottom: 20px;
      width: 100%;
      height: 56px;

      &:last-child {
        margin: 20px 0 0;
      }
    }

    .btn-list-page {
      @include flex(row, center, center);
      gap: 15px;
      width: 100%;
      height: 56px;
      border: 1px solid #78a4ee;
      border-radius: 8px;
      background-color: #f1f4fb;
      color: #4a8eff;
      font-size: 16px;

      img {
        margin-bottom: 2px;
      }

      &:disabled {
        border-style: none;
        background-color: #dbdbdb;
        opacity: 0.6;
        filter: grayscale(100%);
      }
    }
  }

  .content-title {
    &__time {
      @include flex(row);
      margin-bottom: 56px;
      font-size: 18px;
      line-height: 1.3;

      .el-icon-time {
        margin-right: 8px;
        font-size: 24px;
        font-weight: 500;
      }
    }

    &__bector-wrap {
      @include flex();
      gap: 10px;
      margin-bottom: 30px;

      .bector-icon {
        width: 50px;
        height: 8px;
        border-radius: 4px;
        background-color: #78a4ee;

        &:hover {
          cursor: unset;
        }

        &:disabled {
          background-color: #000;
          opacity: 0.1;
        }
      }
    }

    &__confirm-icon {
      margin-bottom: 12px;
    }

    &__main-text {
      margin-bottom: 30px;
      line-height: 1.3;
      font-size: 32px;
      font-weight: bold;
      color: #505050;
    }

    .btn-back {
      margin: 0;
      width: 316px;
      height: 70px;
      border: 2px solid #505050;
      border-radius: 16px;
      font-size: 25px;
      font-weight: 600;
      background-color: #f4f4f4;
      color: #505050;
    }
  }

  .list-template {
    height: 100%;

    ul {
      @include flex(column);
      gap: 20px;
      height: 100%;

      li {
        @include flex();
        flex-grow: 0.2;
      }
    }

    &__wrap {
      position: relative;
      @include flex(column, stretch, center);
      padding: 20px;
      width: 472px;
      border-radius: 16px;
      background-color: #fff;
      z-index: 2;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          filter: brightness(0.9);
        }
      }
    }

    &__title {
      div {
        @include flex();

        p {
          margin-left: 6px;
          line-height: 2;
          font-size: 20px;
          color: #505050;
        }

        @include mq-height-max(768px) {
          h3 {
            font-size: 20px;
          }
        }
      }

      .lecture-date-wrap {
        @include flex(row, center);
        gap: 15px;

        h3 {
          color: #505050;
        }

        img {
          margin-top: 4px;
        }
      }

      .title-middle {
        font-size: 20px;
        color: #7e7e7e;

        span {
          color: #b1b1b1;
        }
      }

      .lecture-staff {
        @include flex();
        gap: 4px;
        color: #505050;

        p {
          margin: 0;
          line-height: normal;
          font-size: 18px;
          font-weight: 600;
        }

        span {
          line-height: normal;
          font-size: 18px;
        }
      }

      .lecture-time {
        font-size: 30px;
        color: #000;
      }

      .lecture-date {
        font-size: 20px;
        color: #bcbcbc;
      }
    }

    &__profile {
      img {
        margin-bottom: 8px;
        width: 64px;
        height: 64px;
        border: 1px solid #f0f0f0;
        border-radius: 50%;
      }
    }

    &__types {
      margin: 2px 0 0;
      color: #bebebe;
    }

    &__info {
      position: relative;
      margin-top: 20px;
      z-index: 4;
      .info-date,
      .info-count {
        @include flex();
        gap: 8px;
      }

      @include mq-height-max(700px) {
        display: none;
      }
    }

    &__info-time {
      @include flex();
      margin-top: 20px;
      padding-left: 10px;
      border-left: 2px solid #505050;
      gap: 8px;
      color: #a5a5a5;

      p {
        color: #505050;
      }
    }

    &__watermark {
      @include flex();
      position: absolute;
      bottom: 15px;
      right: 25px;
      font-size: 40px;
      font-weight: bold;
      color: #f8f8f8;

      div {
        margin-left: 12px;
      }
    }
  }

  .success-checkin {
    @include flex(column, stretch, space-between);
    padding: 24px 50px 30px;
    max-height: 600px;
    border-radius: 16px;
    background-color: #fff;

    &__header {
      margin-bottom: 30px;

      img {
        width: 60px;
        height: 60px;
      }

      h2 {
        line-height: 1;
        font-weight: 500;
      }
    }

    &__content {
      margin-bottom: 16px;
      font-size: 20px;

      .content-title {
        color: #bcbcbc;
      }
    }

    @include mq-max(1200px) {
      &__header {
        margin-bottom: 20px;

        h2 {
          font-size: 24px;
        }
      }

      &__content {
        margin-bottom: 14px;

        .content-title,
        .content-body {
          font-size: 1.6vw;
        }
      }
    }

    .remaining {
      @include flex();
      gap: 100px;
    }

    &__hide-text {
      margin: 10px 0 5px 0;
      text-align: center;
      font-size: 20px;
      color: #4a8eff;

      @include mq-max(1200px) {
        margin: 8px 0 4px 0;
        font-size: 16px;
      }
    }

    &__bottom {
      @media (hover: hover) {
        button:hover {
          filter: brightness(1.1);
        }
      }
    }
  }
}
</style>
